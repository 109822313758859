.proofOfActionModalWrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.proofOfActionSubtitle {
  padding: 24px 0px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  text-align: left;
  opacity: 0.6;
  color: #FFFFFF;
}

.btnsWrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
}