@use "../../../styles/colors.module.scss" as colors;
@use "../../../styles/mixins.scss" as mixins;
@use "../../../styles/constants.module.scss" as consts;

.user-tasks,
.general-tasks {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: hidden;

  @include mixins.breakPoint(xl) {
    height: calc(100svh - 224px);
    width: 100%;
    overflow-y: auto;
    @include mixins.customScrollbar;
  }
}
