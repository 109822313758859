@use "../../styles/constants.module.scss" as consts;

.pageGrid {
  display: grid;
  grid-template-areas:
    "staking tiketsCount"
    "staking stakedCoins"
    "ruffle ticketsPerDay";
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 18px;
  height: 100%;
  width: 100%;
  color: #ffffff;
  font-family: Rubik, sans-serif;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    grid-template-areas:
      "tiketsCount tiketsCount"
      "ticketsPerDay stakedCoins"
      "staking staking"
      "ruffle ruffle"
      "helpInfo helpInfo"
      "rewards rewards";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 86px auto auto auto auto auto auto;
    gap: 10px;
  }
}

.notStakedGrid {
  grid-template-areas:
    "staking helpInfo"
    "staking helpInfo"
    "staking helpInfo"
    "staking rewards"
    "ruffle rewards"
    "ruffle rewards";
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;

  .rewardsWrapper,
  .helpInfoWrapper {
    display: flex;
  }

  .ticketsWrapper,
  .ticketsPerDayWrapper,
  .stakedWrapper {
    display: none;
  }

  @media screen and (max-width: consts.$mobile-breakpoint) {
    grid-template-areas:
      "tiketsCount ticketsPerDay stakedCoins"
      "staking staking staking"
      "ruffle ruffle ruffle"
      "helpInfo helpInfo helpInfo"
      "rewards rewards rewards";
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 86px auto auto auto auto;
    gap: 10px;

    .ticketsWrapper,
    .ticketsPerDayWrapper,
    .stakedWrapper {
      display: flex;
    }
  }
}

@mixin card {
  border-radius: 25px;
  background: #151517;
  border: 1px solid #1d1d20;
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    padding: 10px;
    border-radius: 14px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
  }

  &:not([class*="ruffleWrapper"]) h3 {
    @media screen and (max-width: consts.$mobile-breakpoint) {
      font-size: 10px;
      line-height: 10px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    opacity: 0.6;

    @media screen and (max-width: consts.$mobile-breakpoint) {
      font-size: 10px;
      line-height: 10px;
    }
  }

  div>span {
    font-size: 48px;
    font-weight: 500;
    line-height: 48px;
    text-align: left;

    @media screen and (max-width: consts.$mobile-breakpoint) {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

@mixin bg {
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    background-position: right top;
    background-size: cover;
  }
}

.stakingWrapper {
  @include card;
  position: relative;
  grid-area: staking;
  align-items: center;
  width: 100%;
  border: none;
  box-shadow: inset 0 0 0px 1px #1d1d20;

  @media screen and (max-width: 534px) {
    border-radius: 25px;
  }

  .titleRow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 534px) {
      justify-content: space-between;
    }
  }

  .stakingConvexity {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 2px) rotate(180deg);
  }

  .stakingTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #b364d8;

    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color: #ffffff;

      @media screen and (max-width: 534px) {
        display: block;
      }
    }
  }

  .stakingbalanceTop {
    grid-area: balance;
    align-self: center;
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: 534px) {
      display: none;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      text-align: right;
      color: #ffffff66;

      span {
        color: #ffffff;
        display: block;
      }
    }
  }

  .stakingProgressBar div:first-child {
    width: 100%;
    aspect-ratio: 12 / 8;
    max-width: initial;
    height: initial;
    max-height: initial;
  }

  .stakingTimerTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 12px;
  }

  .stakingTimerDigits {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media screen and (max-width: 450px) {
      gap: 6px;
    }

    .stakingTimerDigit {
      position: relative;
      font-size: 46px;
      font-weight: 500;
      line-height: 46px;

      @media screen and (max-width: 450px) {
        font-size: 30px;
        line-height: 30px;
      }

      span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + 4px);
        font-size: 12px;
        font-weight: 500;
        line-height: 15.48px;
        text-align: left;
        text-transform: uppercase;
        color: #ffffff66;

        @media screen and (max-width: 450px) {
          font-size: 10px;
          line-height: 12.9px;
        }
      }
    }

    .stakingTimerLimiter {
      font-size: 35px;
      font-weight: 500;
      line-height: 35px;
      opacity: 0.3;

      @media screen and (max-width: 450px) {
        font-size: 28px;
        line-height: 28px;
      }
    }
  }

  .stakingActionsContainer {
    display: grid;
    grid-template-areas: "input input" "quickInput balance";
    gap: 19px;
    width: 100%;

    @media screen and (max-width: 534px) {
      grid-template-areas: "input" "quickInput";
      gap: 8px;
    }

    >.stakingButton {
      width: 100%;
      max-width: 100%;

      @media screen and (min-width: 534px) {
        display: none;
      }
    }
  }

  .stakingInputContainer {
    grid-area: input;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 88px;
    padding: 0px 8px 0px 24px;
    gap: 10px;
    border-radius: 20px;
    background: #212123;
    border: 1px solid rgba(255, 255, 255, 0.1);

    @media screen and (max-width: 534px) {
      height: 56px;
      border-radius: 16px;
    }

    .stakingInput {
      display: flex;
      align-items: center;
      padding-top: 14px;
      gap: 8px;

      @media screen and (max-width: 534px) {
        padding-top: 4px;
      }

      img {
        min-width: 24px;
        width: 24px;
        height: 24px;

        @media screen and (max-width: 534px) {
          min-width: 18px;
          width: 18px;
          height: 18px;
        }
      }

      input {
        outline: none;
        border: none;
        background-color: transparent;
        font-family: Rubik;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        width: 100%;
        display: block;

        @media screen and (max-width: 534px) {
          font-size: 18px;
          line-height: 18px;
        }

        &::placeholder {
          font-family: Rubik;
          font-size: 24px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;

          @media screen and (max-width: 534px) {
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
    }

    @media screen and (max-width: 534px) {
      .stakingButton {
        display: none;
      }
    }
  }

  .stakingButton {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 286px;
    width: 100%;
    height: 56px;
    border-radius: 17px;
    background: linear-gradient(90deg, #b364d8 68.02%, #9749bc 100%);
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-family: Rubik;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;

    transition: opacity 200ms ease;

    &:hover {
      opacity: 0.7;
    }
  }

  .stakingQuickSelect {
    grid-area: quickInput;
    align-self: center;
    display: flex;
    align-items: center;
    gap: 4px;

    @media screen and (max-width: 534px) {
      gap: 8px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 71px;
      height: 36px;
      border-radius: 12px;
      background: #ffffff0d;
      border: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      transition: opacity 200ms ease;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &:disabled,
      &:hover:disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }

      @media screen and (max-width: 534px) {
        width: 100%;
        min-width: 48px;
      }
    }
  }

  .stakingbalance {
    grid-area: balance;
    align-self: center;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 534px) {
      display: none;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      text-align: right;
      color: #ffffff66;

      span {
        color: #ffffff;
      }
    }
  }

  .mobileInfo {
    display: none;
    align-items: center;
    justify-content: space-between;

    img {
      min-width: 26px;
      height: 26px;
    }

    span {
      font-size: 8px;
      font-weight: 400;
      line-height: 11.2px;
      text-align: right;
      opacity: 0.2;
    }

    @media screen and (max-width: 534px) {
      display: flex;
    }
  }
}

.rufflePlaceholder {
  grid-area: ruffle;
  height: 100%;
  position: relative;

  >img {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 50%;
    top: -14px;
    transform: translate(-42%, 0);
  }
}

.ruffleWrapper {
  @include card;
  // grid-area: ruffle;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border: none;
  box-shadow: inset 0 0 0px 1px #1d1d20;

  &.loading {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: consts.$mobile-breakpoint) {
    padding: 0px;
  }

  @media screen and (max-width: 534px) {
    border-radius: 25px;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    padding: 20px;
    gap: 20px;
    height: 361px;
  }

  .ruffleConvexity {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -2px);
  }

  .ruffleRight {
    @include bg;
    background-position: right bottom;
    padding: 38px 15px 18px 38px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: 534px) {
      padding: 0px;
    }

    .ruffleInfo {
      display: flex;
      align-items: center;
      gap: 11px;

      .ruffleIcon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 60px;
        min-width: 60px;
        height: 60px;

        border-radius: 50%;
        background: #212123;
        border: 1px solid #ffffff1a;

        img {
          width: 32px;
          height: 32px;
        }
      }

      span {
        font-family: Rubik, sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 21.8px;
        text-align: left;

        @media screen and (max-width: 534px) {
          padding: 0px;
          font-size: 19px;
        }

        .purple {
          color: white;
        }

        .violet {
          color: #B364D8;
        }
      }
    }
  }

  .butonsWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    button {
      width: 100%;
    }
  }

  .ruffleClaimButtonsContainer {
    display: flex;
    gap: 8px;
    width: 100%;

    button:first-child {
      width: 100%;
    }
  }

  .ruffleClaimButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    border-radius: 17px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    background: #ffffff;
    border: 1px solid #ffffff;

    font-family: Rubik, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
  }

  .ruffleLeft {
    @include bg;
    background-position: left bottom;
    padding: 24px;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 534px) {
      padding: 0px;
    }
  }

  .delimiter {
    position: absolute;
    left: 50%;
    top: 36px;
    bottom: 25px;
    width: 1px;
    background-color: #444445;

    @media screen and (max-width: 534px) {
      display: none;
    }
  }

  &.won {
    grid-template-columns: 1fr;
    background-color: #b263d7;
    box-shadow: inset 0 0 0 1px #9357b2;

    svg.ruffleConvexity path {
      stroke: #9357b2;
    }

    @media screen and (max-width: 534px) {
      background-image: url("../../assets/images/mobileRuffleWon.png");
      background-size: contain;
      background-position: bottom;
      background-repeat: no-repeat;
    }

    .ruffleLeft {
      background-image: url("../../assets/images/ruffleBg4.png");
      background-size: contain;

      @media screen and (max-width: consts.$mobile-breakpoint) {
        background-position: left bottom;
      }

      @media screen and (max-width: 534px) {
        background-image: none;
      }
    }

    .ruffleRight {
      position: absolute;
      width: 50%;
      right: 0;
      top: 0;

      @media screen and (max-width: 534px) {
        position: relative;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
      }
    }

    .delimiter {
      display: none;
    }

    div.ruffleIcon {
      background: #ca88e9 !important;
      border-color: #ca88e9 !important;
    }
  }

  &.lose {
    @media screen and (max-width: 534px) {
      background-image: url("../../assets/images/ruffleBg1.png");
      background-repeat: no-repeat;
      background-size: 130%;
      background-position: bottom left;
    }

    .ruffleLeft {
      background-image: url("../../assets/images/ruffleBg1.png");

      @media screen and (max-width: 1360px) {
        background-size: 130%;
      }

      @media screen and (max-width: consts.$mobile-breakpoint) {
        background-position: bottom left;
      }

      @media screen and (max-width: 534px) {
        background-image: none;
      }
    }

    .ruffleRight {
      background-image: url("../../assets/images/ruffleBg2.png");
      justify-content: flex-start;
      gap: 16px;
    }
  }

  &.unavailable {
    @media screen and (max-width: 534px) {
      background-image: url("../../assets/images/rufflebg3.png");
      background-repeat: no-repeat;
      background-size: 130%;
      background-position: bottom left;
    }

    .ruffleLeft {
      background-image: url("../../assets/images/rufflebg3.png");

      @media screen and (max-width: 1360px) {
        background-size: 130%;
      }

      @media screen and (max-width: consts.$mobile-breakpoint) {
        background-position: bottom left;
      }

      @media screen and (max-width: 534px) {
        background-image: none;
      }
    }

    .ruffleRight {
      background-image: url("../../assets/images/ruffleBg2.png");
      justify-content: flex-start;
      gap: 16px;
    }
  }

  &.waitingForResult {
    @media screen and (max-width: 534px) {
      background-image: url("../../assets/images/rufflebg5.png");
      background-repeat: no-repeat;
      background-size: 130%;
      background-position: bottom left;
    }

    .ruffleLeft {
      background-image: url("../../assets/images/rufflebg5.png");

      @media screen and (max-width: 1360px) {
        background-size: 130%;
      }

      @media screen and (max-width: consts.$mobile-breakpoint) {
        background-position: bottom left;
      }

      @media screen and (max-width: 534px) {
        background-image: none;
      }
    }

    .ruffleRight {
      background-image: url("../../assets/images/ruffleBg2.png");
      justify-content: flex-start;
      gap: 16px;
    }
  }
}

.ticketsWrapper {
  @include card;
  @include bg;
  background-image: url("../../assets/icons/stacking-img.svg");
  grid-area: tiketsCount;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    background-image: url("../../assets/images/mobileStakingTickets.png");
  }
}

.ticketsPerDayWrapper {
  @include card;
  @include bg;
  grid-area: ticketsPerDay;
  border: none;

  .arrowBtn {
    cursor: pointer;

    @media screen and (max-width: consts.$mobile-breakpoint) {
      width: 24px;
      height: 24px;
    }
  }

  @media screen and (max-width: consts.$mobile-breakpoint) {
    min-height: 84px;
  }

  .button {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    .coins {
      display: flex;
      align-items: flex-end;
      gap: 8px;

      img {
        width: 51px;
        min-width: 51px;
      }

      .symbol {
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        padding-bottom: 5px;
        text-transform: uppercase;
      }

      @media screen and (max-width: consts.$mobile-breakpoint) {
        img {
          width: 20px;
          min-width: 20px;
        }

        .symbol {
          padding-bottom: 2px;
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }
}

.stakedWrapper {
  @include card;
  @include bg;
  grid-area: stakedCoins;
  border: none;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    min-height: 84px;
  }

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    width: 100%;

    .unstaked {
      display: flex;
      flex-flow: column;
      text-align: right;

      h3 {
        opacity: 0.6;
        text-align: right;
      }

      div {
        opacity: 0.4;
      }
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrowBtn {
      cursor: pointer;

      @media screen and (max-width: consts.$mobile-breakpoint) {
        width: 24px;
        height: 24px;
      }
    }
  }

  .purple {
    display: flex;
    align-items: flex-end;
    gap: 8px;

    img {
      width: 51px;
      min-width: 51px;
    }

    .symbol {
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      padding-bottom: 5px;
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: consts.$mobile-breakpoint) {
    .purple img {
      width: 20px;
      min-width: 20px;
    }

    .purple .symbol {
      padding-bottom: 2px;
      font-size: 10px;
      line-height: 10px;
    }
  }
}

.rewardsWrapper {
  @include card;
  @include bg;
  grid-area: rewards;

  display: none;

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    color: #b364d8;
  }

  h5 {
    font-size: 17px;
    font-weight: 500;
    line-height: 20.4px;
    text-align: left;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;

    .title {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .buttons {
      display: flex;

      gap: 8px;

      &>* {
        display: flex;
        cursor: pointer;

        justify-content: center;
        align-items: center;
        background: #212123;
        border: 1px solid #9999991a;
        width: 44px;
        height: 44px;
        border-radius: 10px;

        @media screen and (max-width: consts.$mobile-breakpoint) {
          width: 38px;
          height: 38px;
        }

        &:last-child {
          transform: rotate(180deg);
        }
      }
    }
  }

  .rewardCard {
    width: 140px;
    display: flex;
    flex-direction: column;
    border-radius: 22.73px;
    border: 2px solid #212123;
    background-color: #151517;
    overflow: hidden;
    pointer-events: none;

    .name {
      background-color: #212123;
      padding: 9px 13px;
      color: #ffffff66;
      width: 100%;
      text-align: center;
      flex-shrink: 0;
    }

    .rewardCardBackground {
      height: 148px;
    }

    .rewardCardImage {
      height: 148px;
      object-fit: cover;
    }
  }

  @media screen and (max-width: consts.$mobile-breakpoint) {
    display: flex;

    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;

      color: #b364d8;
    }

    h5 {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
    }

    padding: 20px 20px 24px;
  }
}

.helpInfoWrapper {
  @include card;
  @include bg;
  grid-area: helpInfo;
  justify-content: flex-start;
  gap: 16px;

  display: none;

  &_scroll {
    overflow: auto;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    text-align: left;
    text-transform: none;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    opacity: 60%;
    text-transform: none;
  }

  @media screen and (max-width: consts.$mobile-breakpoint) {
    display: flex;

    h3 {
      font-size: 20px;
      font-weight: 500;
      line-height: 25.8px;
      text-align: left;
      text-transform: none;
    }

    p {
      font-size: 13px;
      font-weight: 400;
      line-height: 18.2px;
      text-align: left;
      opacity: 60%;
      text-transform: none;
    }
  }
}

.questionButton {
  border-color: #ffffff !important;
  min-width: 55px;
  min-height: 55px;
}

.rewardsButton {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}


.ticketsBody {
  grid-area: tiketsCount;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background-color: #151517;
  border-radius: 25px;
  border: 1px solid #1d1d20;

  @media (max-width: 1300px) {
    padding: 14px;
    min-height: 84px;
  }

  .ruffleConvexity {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -2px);
  }

  .ruffleConvexityBottom {
    position: absolute;
    left: 50%;
    bottom: -4px;
    transform: translate(-50%, -2px) rotate(180deg);
  }

  .ticketsHrTop {
    position: absolute;
    top: -3px;
    width: 100%;
    height: 2px;
    left: 0;
    background-color: #111112;
    border: none;
    z-index: 10;
  }

  .ticketsHrBottom {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    background-color: #111112;
    border: none;
    z-index: 10;
    bottom: -3px;
  }

  &::after {
    content: "";
    width: 1px;
    height: 70%;
    right: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #2d2d2e;
    position: absolute;

    @media (max-width: 1200px) {
      height: 30%;
    }
  }

  .left,
  .right {
    width: 50%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .right {
    padding-left: 30px;

    h3 {
      span {
        opacity: 0.6;
      }
    }

    @media (max-width: consts.$mobile-breakpoint) {}

    @media (max-width: 380px) {
      gap: 10px;
    }
  }

  h3,
  span {
    position: relative;
    z-index: 2;
  }

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 17.6px;

    @media (max-width: 1300px) {
      font-size: 14px;
      line-height: 14px;
    }

    @media screen and (max-width: consts.$mobile-breakpoint) {
      font-size: 10px;
      font-weight: 500;
      line-height: 10px;
    }
  }

  .value {
    font-size: 42px;
    font-weight: 500;
    line-height: 42px;

    @media (max-width: 1300px) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  .ticketsBg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    height: fit-content;
    max-height: 226px;
  }

  .boostBtn {
    position: relative;
    z-index: 2;
    cursor: pointer;
    padding: 6px 14px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    background-color: #2c2c2e;
    border-radius: 10px;
    border: 1px solid #414143;
    display: flex;
    gap: 5px;
    align-items: center;

    @media (max-width: 1300px) {
      font-size: 12px;
      line-height: 12px;

      .btnArrow {
        display: none;
      }
    }
  }
}


.ticketsPerDayAmount {
  font-size: 32px;
  line-height: 32px;
  margin-top: 9px;
  font-weight: 500;

  @media (max-width: consts.$mobile-breakpoint) {
    font-size: 20px;
    line-height: 20px;
  }
}

.stakingInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 534px) {
    gap: 6px;
  }
}

.ticketsAmountWrapper {
  display: flex;
  gap: 4px;
  color: #FFFFFF66;
  font-size: 12px !important;
  line-height: 12px !important;
  font-weight: 500;
  text-transform: lowercase !important;
  opacity: 1 !important;
}

.ticketsAmount {
  color: #83D987;
  font-size: 12px !important;
  line-height: 12px !important;
}
