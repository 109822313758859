@use "../../styles/constants.module" as consts;
@use "../../styles/mixins" as mixins;
@use "../../styles/colors.module" as colors;

.text {
  font-weight: 400;
  color: transparentize(colors.$color-white, 0.4);

  &--s {
    font-size: 14px;
    line-height: 21px;
  }

  &--m {
    font-size: 16px;
    line-height: 22px;
  }

  a {
    color: colors.$color-purple;
  }
}
