@use "../../../../styles/constants.module" as consts;
@use "../../../../styles/colors.module" as colors;
@use "../../../../styles/mixins" as mixins;

.otp-code-input {
  $this: &;

  margin-top: 8px;
  position: relative;

  &__input-wrap {
    display: grid !important;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    column-gap: 2px;

    @include mixins.breakPoint(xl) {
      column-gap: 8px;
    }
  }

  &__input {
    width: 100%;
    height: 63px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: transparentize(colors.$color-white, 0.6);
    @include mixins.buttonDark(20px);
    transition: all 0.2s ease;

    @include mixins.breakPoint(xl) {
      height: 100px;
      font-size: 26px;
    }

    &:focus {
      outline: none;

      &::placeholder {
        opacity: 0;
      }
    }

    &::selection {
      background-color: transparent;
    }

    &--has-value {
      color: colors.$color-white;
      border-color: colors.$color-purple;
    }

    &--selected {
      color: transparentize(colors.$color-white, 0.6);
      border-color: colors.$color-purple;
    }

    &--error {
      border-color: colors.$color-red;
    }
  }

  &__error {
    display: block;
    margin-top: 14px;
    padding-left: 12px;
    font-weight: 400;
    color: colors.$color-red;

    @include mixins.breakPoint(xl) {
      position: absolute;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
