@use "../../../styles/colors.module.scss" as colors;
@use "../../../styles/mixins.scss" as mixins;

.button {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 44px;
  min-height: 44px;
  padding: 10px 16px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #2C2C2E;

  font-family: Rubik, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  transition: opacity 200ms ease, border-color 200ms ease, color 200ms ease, background-color 200ms ease;

  &:hover {
    background-color: #252527;
  }

  &.large {
    width: 100%;
    height: 56px;
    min-height: 56px;
    padding: 20px;
    border-radius: 17px;
  }

  &.medium {
    height: 32px;
    min-height: 32px;
    padding: 0px;
    border-radius: 8px;
  }

  &.small {
    width: 24px;
    height: 24px;
    min-height: 24px;
    padding: 0px;
    border-radius: 6px;
  }

  &.iconButton {
    min-width: 44px;
    padding: 8px;

    &.large {
      min-width: 56px;
    }

    &.small {
      min-width: 24px;
    }
  }

  &.secondary {
    border: 1px solid colors.$color-purple;
    background-color: colors.$color-purple;

    &:disabled {
      opacity: 1;
      background: linear-gradient(90deg, rgba(179, 100, 216, 0.2) 68.02%, rgba(151, 73, 188, 0.2) 100%);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    &:hover:not(:disabled) {
      border: 1px solid #9555B2;
      background-color: #9555B2;
      color: colors.$color-white !important;
    }
  }

  &.green {
    border: 1px solid #83D987;
    background-color: #83D987;
    color: #000000;
  }

  &.white {
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    color: #000000;
  }

  &.outlined {
    background-color: transparent;
    color: #FFFFFF;

    &:hover:not(:disabled) {
      background-color: #303030;
    }
  }

  &.upper {
    text-transform: uppercase;
  }

  &.decorColor-purple {
    color: colors.$color-purple;
    border-color: colors.$color-purple;
    background-color: #212123;

    &:active {
      color: colors.$color-white;
      border-color: #9555B2;
      background-color: #9555B2;
    }

    @include mixins.breakPoint(lg) {
      &:hover {
        color: colors.$color-white;
        border-color: #9555B2;
        background-color: #9555B2;
      }
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
