@use "../../../../../styles/constants.module" as consts;
@use "../../../../../styles/mixins" as mixins;

.referral-table-header {
  display: grid;
  column-gap: 12px;
  grid-template-columns: 1fr 110px;
  padding: 0 12px 10px;
  font-size: 14px;
  font-weight: 500;
  opacity: consts.$opacity;
}
