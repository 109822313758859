@use "../../../styles/colors.module.scss" as colors;
@use "../../../styles/mixins.scss" as mixins;
@use "../../../styles/fonts-mixins.scss" as fonts;
@use "../../../styles/constants.module.scss" as consts;

.task-modal {
  bottom: 20px;
  margin: auto 0;
  max-height: max-content;
  position: fixed;
  top: 31px;
  padding: 15px;

  .card-wrapper {
    height: auto;
  }

  &__button-close {
    margin-left: auto;
  }

  &__title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__body {
    margin-top: 27px;
    flex-grow: 1;
    //height: calc(100% - 131px);
    //overflow: auto;
    //@include mixins.customScrollbar();
  }

  &__body-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 573px;
    @include mixins.customScrollbar;
  }

  &__body-header {
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
  }

  //&__description {
  //  margin-top: 24px;
  //  font-weight: 400;
  //  font-size: 16px;
  //  line-height: 22px;
  //  color: transparentize(colors.$color-white, 0.4);
  //}

  &__goto-btn {
    display: flex;
    align-items: center;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 14px 0;
  }

  &__goto-btn {
    column-gap: 15px;
    color: colors.$color-purple;
    transition: color consts.$transition-time;


    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    @include mixins.breakPoint(sb) {
      &:hover {
        color: transparentize(colors.$color-purple, 0.2);
      }
    }

    ;
  }

  &__goto-btn-wrapper {
    display: flex;
    align-items: center;
    column-gap: 18px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 14px;
    margin-top: auto;
    font-weight: 500;
  }
}
