@use "../../styles/colors.module" as colors;
@use "../../styles/mixins" as mixins;
@use "../../styles/constants.module" as consts;

.checkbox-field {
  $this: &;

  display: flex;
  align-items: center;
  column-gap: 11px;
  cursor: pointer;

  &__label {
    color: transparentize(colors.$color-white, 0.4);
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;

    a {
      color: colors.$color-white;

      @include mixins.breakPoint(xl) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__container {
    width: 24px;
    height: 24px;
    order: -1;
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked {
      + #{$this}__checked {
        &::before {
          background: colors.$color-purple;
          border-color: colors.$color-purple;
        }

        &::after {
          background: transparentize(colors.$color-white, 0.43);
        }
      }
    }
  }

  &__checked {
    position: relative;
    cursor: pointer;
    display: block;
    width: inherit;
    height: inherit;

    &::before,
    &::after {
      @include mixins.pseudo;
    }

    &::before {
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      transition: all consts.$transition-time;
      background-color: colors.$new-color-gray-light;
      border-radius: 9px;
      border: 1px solid rgba(153, 153, 153, 0.1);
    }

    &::after {
      width: 14px;
      height: 14px;
      top: calc(50% - 7px);
      left: calc(50% - 7px);
      transition: all consts.$transition-time;
      border-radius: 5px;
    }
  }
}
