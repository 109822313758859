@use "../../../../styles/constants.module" as consts;
@use "../../../../styles/mixins" as mixins;

.signup-card {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
  width: 100%;
  padding: 19px;
  border: 1px solid #1E1E20;
  border-radius: 25px;
  background-color: transparent;
  transition: background-color 300ms ease;

  &.disabled {
    filter: grayscale(1);
    cursor: not-allowed;
  }

  @include mixins.breakPoint(2sm) {
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    gap: 56px;
    width: 297px;
    padding: 34px 27px 25px;
  }

  cursor: pointer;

  &:hover {
    background-color: #1E1E20;

    svg {
      transform: translateX(10px);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74px;
    height: 74px;
    margin: 0;
    border: 2px solid #232325;
    border-radius: 20px;
    background: linear-gradient(148.47deg, rgba(217, 217, 217, 0.05) 29.79%, rgba(115, 115, 115, 0) 70.59%);

    img {
      width: 36px;
    }

    @include mixins.breakPoint(2sm) {
      width: 126px;
      height: 126px;
      margin: 0 auto;
      border-radius: 34px;
      border: 3px solid #232325;

      img {
        width: 54px;
      }
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;

    h3 {
      font-size: 22px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
    }

    @include mixins.breakPoint(2sm) {
      gap: 10px;
      font-size: 28px;
      line-height: 28px;
    }
  }

  &__register {
    display: flex;
    align-items: center;
    gap: 9px;

    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      text-align: left;
      text-transform: uppercase;
      color: #B364D8;

      @include mixins.breakPoint(2sm) {
        font-size: 15px;
        line-height: 15px;
      }
    }

    svg {
      transition: transform 300ms ease;
      transform: translateX(0px);
    }
  }
}
