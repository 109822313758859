@use "../../../../styles/mixins" as mixins;

.rarity {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 8px;

  font-size: 9.13px;
  font-weight: 500;
  line-height: 9.13px;
  text-align: center;

  color: #000000;
  background-color: #CCCCCC;

  &.regular {}

  &.large {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    text-align: center;
    padding: 12px;

    @include mixins.breakPoint(sm) {
      font-size: 17.69px;
      line-height: 17.69px;

    }
  }
}
