@use "../../styles/mixins" as mixins;

.wrapper {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(3, auto);
  gap: 10px;
  width: 100%;
  height: 100%;
  max-width: calc(100vw - 16px);

  @include mixins.breakPoint(sm) {
    grid-template-columns: 14fr 11fr 10fr;
    grid-template-rows: auto;
  }
}
