@use "src/styles/colors.module.scss" as colors;
@use "src/styles/constants.module.scss" as consts;
@use "src/styles/mixins.scss" as mixins;

.betaCodeCongrats {
  text-align: center;
}

.betaCodeCongratsPicture {
  margin-bottom: 20px;

  img {
    margin: 0 auto;
  }

  @include mixins.breakPoint(lg) {
    margin-bottom: 32px;
  }
}

.betaCodeCongratsTitle {
  font-size: 23px;
  line-height: 23px;

  @include mixins.breakPoint(lg) {
    font-size: 28px;
    line-height: 28px;
  }
}

.betaCodeCongratsText {
  margin-top: 10px;

  @include mixins.breakPoint(lg) {
    margin-top: 16px;
  }
}

.betaCodeCongratsButton {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all consts.$transition-time;
  width: 100%;
  height: 56px;
  padding: 0 58px;
  border-radius: 17px;
  background-color: colors.$color-purple;
  color: colors.$color-white;
  border-color: transparentize(colors.$color-white, 0.8);

  &:active {
    background-color: #7A4792;
  }

  @include mixins.breakPoint(xl) {
    &:hover {
      background-color: #9555B2;
    }
  }

  &:disabled {
    background-color: #35253E;
    color: #3E3E3E;
    border-color: #35253E;
  }

  @include mixins.breakPoint(lg) {
    margin-top: 32px;
  }
}
