@use "../../styles/constants.module" as consts;
@use "../../styles/colors.module" as colors;
@use "../../styles/mixins" as mixins;

.setup-page {
  &__image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: calc(100% + 23px);
    height: 100%;
    margin: -5% -13px 0;
    overflow: hidden;
    border-radius: 25px;

    @include mixins.breakPoint(xl) {
      width: calc(100% + 56px);
      margin: -10% -32px 0;
    }

    &::after {
      @include mixins.pseudo;
      width: 100%;
      height: 100%;
      top: 0;
      background-image: url(../../assets/images/loginGradient.png);
      background-size: 130% 105%;
      background-repeat: no-repeat;
      background-position: bottom center;
    }
  }

  &__image {
    position: relative;
    z-index: 5;
    padding-top: 5%;
    object-fit: contain;
    object-position: bottom;
    height: 100%;
    width: 100%;

    @include mixins.breakPoint(xl) {
      padding-top: 10%;
    }
  }
}
