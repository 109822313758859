.wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 12px;
  max-width: 100%;

  &_withChip {
    gap: 0;
  }

  &_card {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    min-width: 48%;
    width: 100%;
    max-width: 159px;
    &_check {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -2px);
    }

    &_img {
      aspect-ratio: 1/1;
      width: 100%;
      height: 100%;
      //min-width: 159px;
      //min-height: 159px;
      border-radius: 27px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      max-width: 159px;
    }

    div[class*="rarity"] {
      transform: translateY(-50%);
    }
  }

  &_title {
    font-size: 17px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
  }
}
