.container {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  align-items: center;
  justify-content: center;

  width: 112px;
  height: 27px;
  padding: 4px;
  gap: 5px;
  border-radius: 10px;
  background: #212123;
  border: 1px solid #FFFFFF1A;
  background: #212123;

  img {
    min-width: 18px;
    width: 18px;
    height: 17px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: #EEA12B;
  }
}