@use "../../../../../styles/constants.module.scss" as consts;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 138px;
  height: 44px;
  padding: 10px 16px 10px 16px;
  gap: 10px;
  border-radius: 10px;
  background: #212123;
  border: 1px solid rgba(255, 255, 255, 0.1);

  @media screen and (max-width: consts.$mobile-breakpoint) {
    max-width: 56px;
    min-width: 56px;
  }

}