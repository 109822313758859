@use "../../styles/constants.module" as consts;
@use "../../styles/colors.module" as colors;
@use "../../styles/mixins" as mixins;

button.btn {
  $this: &;

  font-family: consts.$main-font-family;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  border: 1px solid transparent;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all consts.$transition-time;

  svg {
    width: 24px;
    height: 24px;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &--full-width {
    width: 100%;
  }

  &--s {
    height: 39px;
    border-radius: 14px;
  }

  &--m {
    height: 56px;
    padding: 0 58px;
    border-radius: 17px;
  }

  &--pink {
    background-color: colors.$color-purple;
    color: colors.$color-white;
    border-color: transparentize(colors.$color-white, 0.8);

    &:active {
      background-color: #7A4792;
    }

    @include mixins.breakPoint(xl) {
      &:hover {
        background-color: #9555B2;
      }
    }

    &:disabled {
      background-color: #35253E;
      color: #3E3E3E;
      border-color: #35253E;
    }
  }

  &--white {
    background-color: colors.$color-white;
    color: colors.$color-black;
  }

  &--grey {
    background-color: #2C2C2E;
    color: colors.$color-white;
    border-color: transparentize(colors.$color-white, 0.9);

    &:active {
      background-color: #232323;
      color: #D0D0D0;
      border-color: #2D2D2D;
    }

    @include mixins.breakPoint(xl) {
      &:hover {
        background-color: #272729;
        color: #D0D0D0;
        border-color: #303030;
      }
    }

    &:disabled {
      background-color: #1A1A1C;
      color: #444445;
      border-color: #1A1A1C;
    }
  }
}
