@use "../../../../../styles/mixins" as mixins;
@use "../../../../../styles/colors.module" as colors;
@use "../../../../../styles/constants.module" as consts;

@mixin tableText() {
  &::before {
    @include mixins.pseudo;
    position: relative;
    width: 20px;
    height: 20px;
    background: url("../../../../../assets/images/pix_icon_userinfo.svg") no-repeat center/cover;
  }
}

.referral-table-row {
  $this: &;

  padding: 14px 10px;
  display: grid;
  align-items: center;
  column-gap: 12px;
  grid-template-columns: 1fr 110px;
  border-radius: 19px;

  @include mixins.breakPoint(md) {
    padding: 5px 9px 7px;
    column-gap: 12px;
    grid-template-columns: 1fr 110px;
  }

  &:nth-child(odd) {
    background-color: rgba(colors.$color-white, 0.05);
  }

  &__data-inner {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  &__points {
    display: flex;
    column-gap: 5px;
    color: colors.$color-yellow;
    @include tableText();

    @include mixins.breakPoint(md) {
      display: none;
    }
  }

  &__cell {
    &--user-dto {
      column-gap: 12px;
      align-items: center;
      order: -1;

      @include mixins.breakPoint(md) {
        order: initial;
      }
    }

    &--points {
      display: flex;

      #{$this}__table-text {
        color: colors.$color-yellow;
        @include tableText();
      }
    }
  }

  &__table-text {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  &__user-img {
    position: relative;

    &:after {
      @include mixins.pseudo;
      width: 20px;
      height: 20px;
      right: -3px;
      top: -1px;
    }
  }

  &__data-inner {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  &__data {
    display: flex;
    text-align: left;
    overflow: hidden;
    word-break: break-all;
  }

  &__table-date {
    color: transparentize(colors.$color-white, .6);
    font-size: 14px;
  }
}

.user-avatar {
  width: 48px;
  height: 48px;
  @include mixins.buttonDark(50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
