@use "../../../../../styles/colors.module.scss" as colors;
@use "../../../../../styles/mixins.scss" as mixins;
@use "../../../../../styles/constants.module.scss" as consts;

.referrals-copy-link {
  background-color: colors.$color-purple;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 14px;
  padding: 21px 14px;

  &__label {
    margin-right: 8px;
    flex-shrink: 0;
    color: transparentize(colors.$color-white, .7);
    font-size: 14px;
    font-weight: 500;
  }

  &__input {
    font-family: consts.$main-font-family;
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
    background: transparent;
    border: 0;
    padding-right: 15px;
    @include mixins.textEllipsis;

    @include mixins.breakPoint(sb) {
      font-size: 16px;
      padding-right: 0;
    }
  }

  &__button {
    margin-left: auto;
    opacity: 1;
    transition: opacity consts.$transition-time;

    &:active {
      opacity: 0.6;
    }

    @include mixins.breakPoint(xl) {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__notification {
    margin-right: 5px;
    font-size: 14px;
  }
}
