@import "../../styles/colors.module.scss";
@import "../../styles/constants.module.scss";

$border-radius: 17px;

.wrapper {
  display: grid;
  grid-template-columns: 48px calc(100% - 106px) 48px;
  gap: 5px;
  height: 48px;

  >button, >input {
    display: flex;
    align-items: center;
    border: 1px solid $colo-gray;
    border-radius: $border-radius;
    background-color: transparent;
    transition: border-color $transition-time ease, background-color 100ms ease;
  }

  >input {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;

    color: $color-gray;
  }

  >button {
    color: $color-white;
    justify-content: center;

    &:hover {
      border-color: $color-light-gray;
    }

    &:active {
      background-color: transparentize($color-purple, 0.7);
    }
  }
}
