@use "../../../../styles/mixins.scss" as mixins;
@use "../../../../styles/constants.module.scss" as consts;

.sidebar-banner {
  display: block;
  overflow: hidden;
  border-radius: 18px;

  @include mixins.breakPoint(sb) {
    border-radius: 23px;
  }

  &__picture {
    display: inherit;

    img {
      width: 100%;
      height: 100%;
    }

    @include mixins.breakPoint(sb) {
      transition: all consts.$transition-time;

      &:hover {
        transform: scale(1.1);
        opacity: 0.8;
      }
    }
  }
}

.sidebar-banner_small {
  height: 67px;
  min-height: 67px;
}

.sidebar-banner_medium {
  height: 97px;
  min-height: 97px;

  @include mixins.breakPoint(sb) {
    height: 91px;
    min-height: 91px;
  }
}
