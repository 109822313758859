@use "../../../../../../styles/mixins.scss" as mixins;

.card {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 21px 21px 16px;
  background-color: #151517;
  max-width: 245px;
  width: 100%;
  max-height: 300px;
  border: 1px solid #1D1D20;
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;

  transition: border-color 300ms ease;

  @include mixins.breakPoint(2sm) {
    padding: 59px 34px 32px;
  }

  &.selected {
    border: 1px solid #B76ADB;
  }

  .avatar {
    position: relative;
    width: 83px;
    height: 83px;
    margin-bottom: 8px;
    z-index: 0;

    @include mixins.breakPoint(2sm) {
      width: 120px;
      height: 120px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid #3A3042;
      border-radius: 50%;
      width: 124px;
      height: 124px;
      opacity: 0.4;

      @include mixins.breakPoint(2sm) {
        width: 180px;
        height: 180px;
      }
    }

    &::after {
      width: 248px;
      height: 248px;
    }

    .shine {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 98px;
      height: 98px;
      border-radius: 50%;
      opacity: 0.5;
      background-color: #B76ADB;
      filter: blur(115px);
    }

    .img {
      aspect-ratio: 1/1;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #3A3042;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
    }

    .level {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 10px;
      right: -20px;
      height: 23px;
      padding: 7px 8px;
      background: #151517;
      border: 1px solid #C64EFF;
      border-radius: 12px;

      font-size: 9px;
      font-weight: 500;
      line-height: 9px;
      text-align: center;
      color: #C64EFF;
    }
  }

  .name {
    position: relative;
    z-index: 1;
    margin-bottom: 4px;

    font-size: 13.79px;
    font-weight: 500;
    line-height: 19.31px;
    text-align: center;

    @include mixins.breakPoint(2sm) {
      font-size: 20px;
      line-height: 28px;
    }

  }

  .social {
    position: relative;
    z-index: 1;
    margin-bottom: 16px;

    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    opacity: 60%;

    @include mixins.breakPoint(2sm) {
      font-size: 14px;
      line-height: 14px;
    }
  }

  .points {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      height: 18px;
      width: 18px;
      min-width: 18px;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      text-align: left;
      color: #EEA12B;


      @include mixins.breakPoint(2sm) {
        font-size: 16px;
        line-height: 16px;
      }

    }
  }
}