@use "../../../../styles/constants.module" as consts;
@use "../../../../styles/mixins" as mixins;

.signup-type-select {
  &__text {
    margin-bottom: 20px;

    @include mixins.breakPoint(2sm) {
      margin-bottom: 22px;
    }
  }

  &__variants {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @include mixins.breakPoint(2sm) {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }
  }
}