@use "../../../../../styles/constants.module.scss" as consts;

.container {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 28px 24px 26px;
  border-radius: 25px;
  background: #151517;
  border: 1px solid #1D1D20;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    padding: 18px 16px;
  }


  .inform_card {
    display: flex;
    flex-direction: column;
    width: 488px;

    @media screen and (max-width: consts.$mobile-breakpoint) {
      display: none;
      width: 100%;
    }

    .title {
      font-size: 22px;
      line-height: 22px;
      font-weight: bold;
      margin-bottom: 16px;
    }

  }


}

.avatarActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    position: fixed;
    z-index: 99;
    max-width: calc(100% - 48px);
    left: 24px;
    bottom: 26px;
  }


  .large_button {
    width: 170px;
    min-width: 170px;
    height: 56px;
    min-height: 56px;
    padding: 20px 10;
    gap: 12px;
    background: #2C2C2E;
    border-radius: 17px;
    border: 1px solid rgba(255, 255, 255, 0.1);

    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;

    @media screen and (max-width: 1200px) {
      min-width: 110px;
    }

    &.purple {
      background: #B364D8;
      width: 100%;
    }
  }
}