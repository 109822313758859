@use "../../styles/colors.module.scss" as colors;
@use "../../styles/mixins.scss" as mixins;
@use "../../styles/fonts-mixins.scss" as fonts;
@use "../../styles/constants.module.scss" as consts;


$padding-v-large: 32px;
$sidebarBorderRadius: 0px 17px 17px 0px;


.sidebar {
  position: sticky;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 8px 16px;
  background-color: colors.$new-color-dark;
  border-radius: $sidebarBorderRadius;

  @include mixins.breakPoint(sb) {
    padding: 16px;
    border-radius: 23px;
  }

  &__logo {
    display: none;

    @include mixins.breakPoint(sb) {
      display: block;
      //padding: 17px 16px;
      width: 180px;


      svg {
        width: 96px;
        height: 34px;
      }
    }
  }

  &__close {
    @include mixins.breakPoint(sb) {
      display: none;
    }
  }

  &__navigation {
    margin-top: 24px;

    @include mixins.breakPoint(sb) {
      margin-top: 41px;
    }
  }

  &__banner {
    margin-top: 8px;
  }

  &__footer {
    margin-top: auto;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__info {
    margin-top: 10px;
  }
}
