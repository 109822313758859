*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;

  &:active,
  &:focus {
    outline: none !important;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
dd,
figure,
fieldset,
button {
  margin: 0;
}

ol,
ul,
dd,
fieldset,
button {
  padding: 0;
}

button {
  border: none;
}

ol,
ul,
dd {
  list-style: none;
}

a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0;
}

button,
input {
  overflow: visible;
  text-transform: none;
  border-radius: 0;
  box-shadow: none;
}

input:not([type='checkbox'], [type='radio']),
textarea {
  appearance: none;
}

picture {
  display: block;
}

//img,
//video {
//  width: 100%;
//  max-width: 100%;
//  height: auto;
//  vertical-align: bottom;
//}

svg {
  a &,
  button & {
    pointer-events: none;
  }

  &:not(:root) {
    overflow: hidden;
  }
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
}
