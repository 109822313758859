.accordionTitle {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  width: 100%;
}

.accordionButton {
  color: #737374;
  font-size: 24px;
  font-weight: 500;
}

.accordionDetailsTitle {
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 12px;
}

.accordionDetailsDescription {
  color: #ffffff81;
  margin-bottom: 8px;
  line-height: 22px;
  font-size: 14px;
}
