@use "../../../../styles/constants.module.scss" as consts;

.stakingInfo {
  display: flex;
  gap: 11px;

  @media (max-width: consts.$mobile-breakpoint) {
    flex-direction: column;
    gap: 0;
  }
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 200px;
  min-width: 300px;
  width: 60%;
  background-color: #151517;
  border: 1px solid #1c1c1e;
  border-radius: 25px;
  padding: 32px;
  height: min-content;

  @media (max-width: consts.$mobile-breakpoint) {
    width: 100%;
    gap: 26px;
  }
}

.rightColumn {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
