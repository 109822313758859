// new app font
// @font-face {
//   font-family: 'PT Mono';
//   src: local('PT Mono'), local('PTMono-Regular'),
//     url('../assets/fonts/PTMono-Regular.woff2') format('woff2'),
//     url('../assets/fonts/PTMono-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
//   ascent-override: 100%;
// }

// @font-face {
//   font-family: 'PT Mono';
//   src: local('PT Mono Bold'), local('PTMono-Bold'),
//     url('../assets/fonts/PTMono-Bold.woff2') format('woff2'),
//     url('../assets/fonts/PTMono-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
//   ascent-override: 100%;
// }

$font-main: Rubik, system-ui;
$font-unifontex: unifontex, sans-serif;

.font-ptmono {
  font-family: $font-main;
  font-weight: normal;
}

.font-unifontex {
  font-family: $font-unifontex;
}

.font-rubik {
  font-family: "Rubik", system-ui;
  font-weight: 400;
  font-style: normal;
}