@use "../../../../styles/colors.module.scss" as colors;
@use "../../../../styles/fonts-mixins.scss" as fonts;

.checkbox {
  @include fonts.text-16;
  margin-top: 10px;
  width: fit-content;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 0;
  }
}

.error {
  line-height: 20px;
  margin-top: 8px;
  color: colors.$color-red;
}