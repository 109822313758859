@use "../../styles/constants.module.scss" as consts;
@use "../../styles/mixins.scss" as mixins;


.top-bar-restyled {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 19px 25px;

  @include mixins.breakPoint(sb) {
    justify-content: flex-end;
    flex-wrap: nowrap;
    column-gap: 8px;
  }

  &__burger {
    flex-shrink: 0;

    @include mixins.breakPoint(sb) {
      display: none;
    }
  }

  &__button-trade {
    display: none;
    height: auto;
    min-width: 161px;
    border-radius: 24px;

    @include mixins.breakPoint(sb) {
      display: flex;
    }
  }

  &__staking {
    order: 3;

    @include mixins.breakPoint(lg) {
      order: 2;
      width: 48% !important;
    }
  }

  &__title {
    order: 3;
  }

  &__mini-profile {
    order: 2;
    flex-grow: 1;
    max-width: 301px;

    @include mixins.breakPoint(xl) {
      order: 3;
    }
  }
}
