@use "../../../../styles/constants.module.scss" as consts;
@use "../../../../styles/fonts-mixins.scss" as fonts;


.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  gap: 14px;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    overflow: visible;
  }
}

div.top_card_root,
div.card_root {
  padding: 21px 24px 25px 17px;
}

div.card_root {
  overflow: hidden;
}

div.top_card_root {
  height: initial;
  min-height: initial;
  display: flex;
  flex-flow: column nowrap;
  gap: 19px;
}

.petsDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
}

.card_container {
  display: flex;
  flex-flow: column;
  height: 100%;
  gap: 37px;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;


  .petname {
    font-size: 34px;
    font-weight: 500;
    line-height: 34px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex-grow: 1;
  gap: 8px;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    align-items: center;
  }
}

.text {
  line-height: 20px;
  color: rgba(255, 255, 255, 0.6);
  overflow-wrap: break-word;
}