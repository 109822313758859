$mobile-breakpoint: 768px;

$sidebar-breakpoint: 980px;

$bp3sm: 436px;
$bp2sm: 480px;
$bpsm: 576px;
$bpmd: 768px;
$bplg: 1024px;
$bpxl: 1280px;
$bp2xl: 1536px;
$bp3xl: 1920px;

$transition-time: 0.25s;

$opacity: 0.4;

$main-font-family: 'Rubik', sans-serif;

:export {
  sidebarBeakpoint: $sidebar-breakpoint;
}
