@use "../../../../../styles/mixins.scss" as mixins;

.button {
  font-family: inherit;
  font-size: 15px;
  color: #FF3D3D;
  text-transform: uppercase;
  @include mixins.cardBase(17px);
  height: 55px;
  padding: 0 15px;
  width: 100%;
}
