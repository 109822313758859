@use "../../styles/constants.module" as consts;
@use "../../styles/colors.module" as colors;
@use "../../styles/mixins" as mixins;

.login-page {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 34px;

    @include mixins.breakPoint(xl) {
      padding: 74px 25px 27px;
    }
  }

  &__image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: calc(100% + 23px);
    height: 100%;
    margin: auto -13px 0;
    overflow: hidden;
    border-radius: 25px;

    @include mixins.breakPoint(xl) {
      width: calc(100% + 56px);
      margin: -100px -32px 0;
    }

    &::after {
      @include mixins.pseudo;
      width: 100%;
      height: 100%;
      top: 0;
      background-image: url(../../assets/images/loginGradient.png);
      background-size: 130% 105%;
      background-repeat: no-repeat;
      background-position: bottom center;
    }
  }

  &__image {
    position: relative;
    z-index: 5;
    padding-top: 69px;
    object-fit: contain;
    object-position: bottom;
    height: 100%;
    width: 100%;

    @include mixins.breakPoint(xl) {
      padding-top: 110px;
    }
  }
}
