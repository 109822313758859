@use "../../../../styles/colors.module.scss" as colors;
@use "../../../../styles/constants.module.scss" as consts;
@use "../../../../styles/fonts-mixins.scss" as fonts;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    overflow: visible;
  }

  .progressBar {
    // height: 100%;
    // max-height: 250px;
    padding: 0px 19px 19px;
    justify-content: flex-start;
    aspect-ratio: 12 / 9;
    height: initial;
    width: initial;
    max-width: initial;
    max-height: initial;

    div:first-child {
      padding: 0;
      width: 100%;
      max-width: initial;
      max-height: initial;
    }
  }

  .level {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    position: relative;
    z-index: 3;

    &_value {
      font-size: 70px;
      font-weight: 500;
      line-height: 70px;
      text-align: center;

      @media screen and (max-width: consts.$mobile-breakpoint) {
        font-family: Rubik;
        font-size: 50px;
        font-weight: 500;
        line-height: 50px;
        text-align: center;
      }
    }

    &_label {
      font-size: 30px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      opacity: 0.3;

      @media screen and (max-width: consts.$mobile-breakpoint) {
        font-family: Rubik;
        font-size: 14px;
        font-weight: 500;
        line-height: 18.06px;
        text-align: center;
      }
    }
  }

  .card_root {
    padding: 34px 21px 25px 29px;
  }

  .card_container {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    gap: 37px;
  }





  .content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    @media screen and (max-width: consts.$mobile-breakpoint) {
      align-items: center;
    }
  }

  .username {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 34px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 8px;

    @media (max-width: consts.$mobile-breakpoint) {
      font-size: 28px;
      line-height: 28px;
    }
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    overflow-wrap: break-word;

    @media (max-width: consts.$mobile-breakpoint) {
      font-size: 14px;
      line-height: 14px;
    }
  }

  .email {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 24px;

    @media (max-width: consts.$mobile-breakpoint) {
      font-size: 15px;
      line-height: 15px;
    }
  }

  .socials {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
  }
}

.editButton {
  opacity: 0.5;
  cursor: pointer;
}