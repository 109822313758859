@use "./constants.module.scss" as theme;
@use "./fonts-assets.scss" as fonts;

$font-size-xxl: 54px;
$font-size-xl: 42px;
$font-size-l: 26px;
$font-size-m: 22px;
$font-size-reg: 18px;
$font-size-s: 16px;

$font-size-mob-xxl: 36px;
$font-size-mob-xl: 30px;
$font-size-mob-l: 26px;
$font-size-mob-xm: 20px;
$font-size-mob-m: 16px;
$font-size-mob-s: 14px;

@mixin f-w-reg {
  font-weight: normal;
}

@mixin f-w-bold {
  font-weight: bold;
}

@mixin f-54 {
  font-size: $font-size-xxl;
  line-height: 58px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: $font-size-mob-xxl;
    line-height: 40px;
  }
}

@mixin f-36 {
  font-size: $font-size-xl;
  line-height: 46px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: $font-size-mob-xl;
    line-height: 32px;
  }
}

@mixin f-30 {
  font-size: 30px;
  line-height: 30px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: $font-size-mob-xm;
    line-height: 24px;
  }
}

@mixin f-24 {
  font-size: 24px;
  line-height: 32px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: $font-size-mob-xl;
    line-height: 32px;
  }
}

@mixin f-20 {
  font-size: $font-size-m;
  line-height: 26px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: $font-size-mob-m;
    line-height: 20px;
  }
}

@mixin f-14 {
  font-size: $font-size-s;
  line-height: 18px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: $font-size-mob-s;
    line-height: 16px;
  }
}

/**
// New font mixins
**/

.text-h28 {
  font-size: 28px;
}

@mixin text-h28 {
  font-size: 28px;
  line-height: 40px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: 24px;
    line-height: 30px;
  }
}

.text-h20 {
  font-size: 20px;
  line-height: 28px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: 18px;
    line-height: 25px;
  }
}

@mixin text-h20 {
  @extend .text-h20;
}



.text-18 {
  font-size: 18px;
  line-height: 25px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: 16px;
    line-height: 20px;
  }
}

@mixin text-18 {
  @extend .text-18;
}

.text-16 {
  font-size: 16px;
  line-height: 20px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: 14px;
    line-height: 19px;
  }
}

@mixin text-16 {
  @extend .text-16;
}

.text-14 {
  font-size: 14px;
  line-height: 19px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: 12px;
    line-height: 18px;
  }
}

@mixin text-14 {
  @extend .text-14;
}

.text-12 {
  font-size: 12px;
  line-height: 18px;

  @media screen and (max-width: theme.$mobile-breakpoint) {
    font-size: 10px;
    line-height: 15px;
  }
}

@mixin text-12 {
  @extend .text-12;
}

.font-bold {
  font-weight: bold;
}

@mixin font-bold {
  @extend .font-bold;
}