@use "../../../../../styles/mixins.scss" as mixins;

.progress {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;

  @include mixins.breakPoint(sb) {
    width: 48px;
    height: 48px;
  }

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: 15px;

    @include mixins.breakPoint(sb) {
      font-size: 16px;
    }

    span {
      display: block;
      font-size: 6px;
      opacity: .5;
    }
  }
}
