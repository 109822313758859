.wrapper {
  position: relative;
  padding: 1px;
  border-radius: 25px;
  background: linear-gradient(123deg, #B364D8 23.72%, rgba(179, 100, 216, 0) 95.34%);
  overflow: hidden;

  .mb {
    margin-bottom: 24px;
  }

  &_scroll {
    padding: 23px 19px 31px 0;
    background: #211926;
    border-radius: 25px;
    height: 100%;
    overflow: hidden;
  }
  &_inner {
    width: 100%;
    height: 100%;
    padding: 0 20px 0 39px;
    overflow-y: auto;
    overflow-x: hidden;

    > * {
      position: relative;
      z-index: 1;
    }

    > h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      margin-bottom: 12px;
    }

    > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: center;
      color: #B7ABBD;
      margin-bottom: 24px;
    }


    .shine {
      position: absolute;
      z-index: 0;
      width: 335px;
      height: 527px;
      top: -230px;
      left: -177px;
      background: #9C4EC18C;
      filter: blur(100px);
    }
  }
}
