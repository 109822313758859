@use "../../../../styles/constants.module.scss" as consts;

.container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 13px;
  height: 100%;
  width: 100%;
  overflow: auto;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    max-width: 100%;
    margin: 0 auto;
    gap: 12px;
  }

  .avatarCard {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 48px;
    height: 100%;
    width: 100%;
    padding: 21px 24px 0px;
    border-radius: 25px;
    overflow: hidden;

    background-color: #1A1A1B;
    background-image: url("../../../../assets/images/pet_bg_green.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;

    @media screen and (max-width: consts.$mobile-breakpoint) {
      max-width: 100%;
      margin: 0 auto;
      aspect-ratio: 7 / 10;
      padding-bottom: 28px;
      gap: 16px;
    }

    &:hover .mask {
      color: #FFFFFF;
      fill: #FFFFFF;
      background-color: rgba(0, 0, 0, 0.4);

      svg path {
        fill: #FFFFFF;
      }
    }

    .shine {
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
      width: 60%;
      height: 80%;
      border-radius: 50%;
      background: rgba(131, 217, 135, 0.55);
      filter: blur(100px);
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      z-index: 3;
      color: rgba(255, 255, 255, 0);
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 300ms ease, color 300ms ease;

      svg path {
        fill: rgba(255, 255, 255, 0);
        transition: fill 300ms ease;
      }
    }

  }

  .avatarReactions {
    height: initial;
    padding: 23px 21px;
    display: flex;
    gap: 27px;
    align-items: center;
    min-height: initial;

    @media (max-width: consts.$mobile-breakpoint) {
      flex-flow: column;
      align-items: flex-start;
      gap: 8px;
    }

    &_title {
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      opacity: 0.4;
    }
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
  }

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .loader_container {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar_container {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;

    &.nft {
      position: absolute;
      top: 0;
      left: 0;
      min-height: 100%;
      min-width: 100%;
      width: 100%;
      height: 100%;

      img {
        height: initial;
        max-height: 100%;
        min-height: 100%;
        min-width: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }

    @media (max-width: consts.$mobile-breakpoint) {
      max-height: calc(100% - 30px);
    }

    img {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      max-height: 120%;
      height: 120%;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.modal_header_text {
  display: none;

  @media screen and (min-width: consts.$mobile-breakpoint) {
    display: block;
  }
}