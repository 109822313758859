@import "../../../../styles/colors.module";
@import "../../../../styles/mixins";

$borderRadius: 25px;

.borderGradientContainer {
  width: 100%;
  padding: 1px;
  border-radius: $borderRadius;

  background: linear-gradient(155.03deg, #1D1D20 23.72%, rgba(43, 44, 48, 0) 95.34%);
}

.wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: $borderRadius;
  background: linear-gradient(135deg, #171719 0%, #1C1C1E 100%);
  @include breakPoint(2sm) {
    padding: 16px 24px 24px;
  }
}

.footer {
  position: relative;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  .text {
    display: flex;
    flex-flow: column nowrap;
    gap: 0px;
    @include breakPoint(2sm) {
      gap: 8px;
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      @include breakPoint(2sm) {
        font-size: 24px;
        line-height: 24px;
      }
    }
    .amount {
      display: flex;
      align-items: flex-end;
      gap: 4px;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      @include breakPoint(2sm) {
        font-size: 24px;
        line-height: 24px;
      }
    }
    .amountLabel {
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      text-align: left;
      opacity: 60%;
    }
  }
}
