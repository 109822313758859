@use "../../styles/colors.module.scss" as colors;
@use "../../styles/mixins.scss" as mixins;
@use "../../styles/fonts-mixins.scss" as fonts;
@use "../../styles/constants.module.scss" as consts;


.language-dropdown {
  &__button {
    padding: 7px 8px;
    display: flex;
    align-items: center;
    column-gap: 4px;
    @include mixins.buttonDark(14px);

    svg {
      width: 10px;
      height: 10px;
      transform: rotate(90deg);
    }
  }

  &__button-text {
    text-transform: capitalize;
    line-height: 1;
  }
}

.languages-dropdown-modal {
  &__wrapper {
    height: 100svh;
    max-height: 551px;
    overflow: hidden;

    @include mixins.cardBase(17px);
    width: 300px;
    padding: 15px 15px 28px;

    @media (min-width: 360px) {
      width: 330px;
    }
  }

  &__list {
    margin-top: 16px;
    height: calc(100% - 142px);
    overflow: auto;
    @include mixins.customScrollbar();
  }

  &__item {
    justify-content: space-between;
    border: 1px solid colors.$colo-gray;
    border-radius: 17px;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 15px 24px;
    margin-top: 8px;
    color: rgba(colors.$color-white, 0.4);
    cursor: pointer;
    transition: all consts.$transition-time;

    @include mixins.breakPoint(sb) {
      &:hover:not(&.is-select) {
        color: colors.$color-white;
        background-color: transparentize(colors.$color-purple, .6);
      }
    };

    &:first-of-type {
      margin-top: 0;
    }

    &.is-select {
      color: colors.$color-white;

      @include mixins.breakPoint(sb) {
        cursor: not-allowed;
      }
    }
  }

  &__info-block {
    display: flex;
    align-items: center;
    column-gap: 10px;
    line-height: 1;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
    height: 46px;
  }

  &__title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__button-close {
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button-action {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 21px;
    padding: 20px 12px;
    text-transform: uppercase;
    border-radius: 17px;
    background-color: colors.$color-purple !important;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
