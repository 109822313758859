@use "../../../../styles/mixins" as mixins;

.wrapper {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 16px;
  cursor: pointer;
  width: calc((100% - 12px) / 3);
  aspect-ratio: 1 / 1;

  @include mixins.breakPoint(sm) {
    flex-flow: row nowrap;
    align-items: center;
    width: unset;
    aspect-ratio: unset;
  }

  &.empty {
    cursor: default;
  }

  &_check {
    position: absolute;
    top: 12%;
    right: unset;
    left: 12%;
    transform: none;
    width: 19px;
    height: 19px;
    @include mixins.breakPoint(sm) {
      top: 50%;
      left: unset;
      right: 6px;
      transform: translateY(-50%);
    }
  }

  &_image {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    background: linear-gradient(135deg, #171719 0%, #1C1C1E 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include mixins.breakPoint(sm) {
      width: 65px;
      height: 65px;
    }

    &_empty {
      width: 120px;
      height: 120px;
      border-radius: 12px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(135deg, #171719 0%, #1C1C1E 100%);

      @include mixins.breakPoint(sm) {
        width: 65px;
        height: 65px;
      }

      &:before {
        content: "?";
        font-family: Rubik;
        font-size: 25px;
        font-weight: 500;
        line-height: 25px;
        text-align: center;
        opacity: 0.23;
      }
    }
  }

  &_content {
    display: none;

    @include mixins.breakPoint(sm) {
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      text-align: left;
      color: rgba(255, 255, 255, 0.4);
    }

    &_empty {
      position: relative;
      width: 104px;
      height: 11px;
      margin-bottom: 22px;
      border-radius: 21px;
      background: #1A1A1C;
      display: none;

      @include mixins.breakPoint(sm) {
        display: block;
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: calc(100% + 6px);
        left: 0;
        width: 57px;
        height: 6px;
        border-radius: 21px;
        background: #1A1A1C;
      }

      &:after {
        top: calc(100% + 16px);
      }
    }
  }
}
