@use "../../../styles/fonts-mixins.scss" as fonts;

.modalBackdrop {
  background: rgba(0, 0, 0, 0.3) !important;

  &:focus {
    outline: none !important;
  }
}

.modalContent {
  position: fixed;
  top: 31px;
  bottom: 20px;
  margin: auto 0;
  max-height: max-content;

  &:focus {
    outline: none !important;
  }
}

.modalRoot {
  display: flex !important;
  height: 100% !important;
  width: 100% !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 40 !important;

  &:focus {
    outline: none !important;
  }
}

.modalBody {
  width: 100%;
  max-width: 454px;
  height: 270px;
  padding: 25px 27px 32px 32px;
  border-radius: 25px;
  background: #151517;
  border: 1px solid #1D1D20;

  &:focus {
    outline: none !important;
  }
}

.scrollableContainer {
  max-height: calc(100vh - 50px) !important;
  overflow-y: auto !important;

  &:focus {
    outline: none !important;
  }

  &__ios {
    max-height: 80vh !important;
  }
}

h3.modalTitle {
  font-family: Rubik;
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  margin: 0;

  @media (max-width: 768px) {
    max-width: 240px;
  }
}

.closeIcon {
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    top: -6px;
    right: 12px;
  }
}

.modalHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalBodyDark {
  background: rgba(38, 35, 50, 0.5) !important;
  box-shadow:
    0px -7px 43px -4px rgba(42, 37, 52, 0.32) inset,
    0px 12px 15px 0px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(35px) !important;
}

@media (max-width: 480px) {
  .modalContentMobileFull {
    max-height: 100%;
  }

  .closeIcon {
    top: 8px;
  }

  .scrollableContainer {
    max-height: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .mobileFitScroll {
    height: fit-content !important;
    max-height: 90svh !important;
  }
}