@use "../constants.module.scss" as consts;

$btn-width: 100%;
$btn-height: 68px;

$btn-h-padd: 46px;
$btn-h-padd-r: 40px;

$btn-font-size: 20px;
$btn-font-height: 30px;


$btn-mob-height: 48px;

$btn-mob-font-size: 14px;
$btn-mob-font-height: 24px;

$btn-mob-h-padd: 32px;

.pixelated_button {
  width: $btn-width;
  height: $btn-height;
  font-size: $btn-font-size;
  line-height: $btn-font-height;
  padding-left: $btn-h-padd;
  padding-right: $btn-h-padd-r;
  text-wrap: nowrap;

  @media screen and (max-width: 450px) {
    max-width: 430px;
    height: $btn-mob-height;
    font-size: $btn-mob-font-size;
    line-height: $btn-mob-font-height;
    padding-left: $btn-mob-h-padd;
    padding-right: $btn-mob-h-padd;
  }
}