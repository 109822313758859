@use "../../../../styles/constants.module.scss" as consts;
@use "../../../../styles/mixins.scss" as mixins;

.info {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__item {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;

    a {
      display: block;
      opacity: .6;
      transition: opacity consts.$transition-time;

      &:active {
        opacity: 1;
      }

      @include mixins.breakPoint(sb) {
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
