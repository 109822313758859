@use "../../../../styles/constants.module.scss" as consts;
@use "../../../../styles/fonts-mixins.scss" as fonts;

div.modalContentWrapperPdRight[class*="cardsWrapper"] {
  padding-right: 20px;
  @media (max-width: consts.$mobile-breakpoint) {
    padding-right: 8px;
  }
}

.editProfileWrapper {
  width: 100%;
  max-height: calc(100vh - 180px);
  overflow: auto;
  padding-right: 12px;
  @media (max-width: consts.$mobile-breakpoint) {
    padding-right: 8px;
  }

  .inputLabel {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: right;
    padding-bottom: 7px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .labelWithPoints {
      @include fonts.font-bold;
      display: flex;
      flex-wrap: nowrap;
      gap: 12px;
      align-items: center;
      padding: 0 12px;

      &_justifyed {
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .twoEditInputsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }

    .inputWrapper {
      flex: 1;

      @media (max-width: 768px) {
        width: 100%;
      }

      .input {
        input {
          height: 48px;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }
  }

  .hideEmailCheckBoxWrapper {

    line-height: 1px;
    margin-top: 10px;
    width: fit-content;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 0px;
    }
  }

  .characters_wrapper {
    @include fonts.text-16;
    display: flex;
    gap: 8px;

    .text_characters {
      opacity: 0.7;

      @media (min-width: consts.$mobile-breakpoint) {
        display: none;
      }
    }

    .text_characters_number {
      display: none;
      opacity: 0.7;

      @media (min-width: consts.$mobile-breakpoint) {
        display: inline;
      }
    }

    .number {
      @include fonts.font-bold;
      opacity: 1;
    }
  }


  .assignedSocials {
    margin-top: 16px;
    padding-top: 14px;

    .assignedSocialsTitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      text-align: left;
      margin-bottom: 24px;
    }

    .assignedSocialsBtn {
      height: 56px;
    }
  }

  .btnsBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-top: 24px;
  }

  .large_button {
    width: 170px;
    min-width: 170px;
    height: 56px;
    min-height: 56px;
    padding: 20px 10;
    gap: 12px;
    background: #2C2C2E;
    border-radius: 17px;
    border: 1px solid rgba(255, 255, 255, 0.1);

    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;


    @media (max-width: 440px) {
      width: 100%;
      min-width: 36vw;
    }

    &.purple {
      background: #B364D8;
      width: 100%;
    }
  }
}
