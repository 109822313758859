@use "../../../styles/colors.module.scss" as colors;
@use "../../../styles/mixins.scss" as mixins;
@use "../../../styles/constants.module.scss" as consts;

.tabs {
  display: flex;
  column-gap: 44px;
  position: relative;
  padding: 0 10px;
  margin-bottom: 20px;

  &::before {
    @include mixins.pseudo;
    width: 100%;
    height: 2px;
    top: 100%;
    left: 0;
    background-color: #232324;
  }

  @include mixins.breakPoint(xl) {
    display: none;
  }

  &__button {
    font-family: consts.$main-font-family;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    padding-bottom: 11px;
    transition: all consts.$transition-time;
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 5px;
    text-transform: uppercase;
    color: transparentize(colors.$color-white, 0.6);

    &::before,
    &::after {
      transition: all consts.$transition-time;
    }

    &::before {
      @include mixins.pseudo;
      width: 100%;
      height: 2px;
      top: 100%;
      background-color: transparent;
    }

    &::after {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      content: attr(data-content);
      position: relative;
      height: 17px;
      min-width: 17px;
      padding: 4px;
      background-color: transparentize(colors.$color-white, 0.6);
      color: colors.$color-black;
      font-size: 12px;
    }

    //@include mixins.breakPoint(sb) {
    //  &:hover:not(&.is-active) {
    //    background-color: transparentize(colors.$color-purple, .6);
    //  }
    //};

    &--active {
      color: colors.$color-purple;

      &::before,
      &::after {
        background-color: colors.$color-purple;
      }
    }
  }
}
