@use "../../../../../styles/colors.module.scss" as colors;
@use "../../../../../styles/mixins.scss" as mixins;
@use "../../../../../styles/constants.module.scss" as consts;

.referrals-copy-code {
  &__label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: transparentize(colors.$color-white, 0.66);
  }

  &__wrapper {
    margin-top: 8px;
    display: flex;
    column-gap: 10px;
    align-items: center;
  }

  &__value {
    font-size: 24px;
    text-transform: uppercase;

    @include mixins.breakPoint(sb) {
      font-size: 34px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    opacity: 0.4;
    transition: opacity consts.$transition-time;

    &:active {
      opacity: 1;
    }

    @include mixins.breakPoint(xl) {
      &:hover {
        opacity: 1;
      }
    }
  }

  &__notification {
    font-size: 14px;
  }
}
