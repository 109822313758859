@use "../../../../styles/colors.module.scss" as colors;
@use "../../../../styles/mixins.scss" as mixins;
@use "../../../../styles/constants.module.scss" as consts;

.claim-header {
  padding: 15px;
  border: 1px solid colors.$new_border_color;
  border-radius: 20px;
  background: linear-gradient(22deg, transparentize(colors.$color-purple, .8) 14%, colors.$new-color-dark 60%);
  overflow: hidden;

  @include mixins.breakPoint(lg) {
    padding: 30px 32px;
    border-radius: 25px;
  }

  &__title {
    position: relative;
    padding-left: 12px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;

    @include mixins.breakPoint(lg) {
      padding-left: 20px;
      font-size: 36px;
    }

    &:before {
      @include mixins.pseudo();
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background-color: colors.$color-purple;
      border-radius: 11px;

      @include mixins.breakPoint(lg) {
        width: 6px;
      }
    }
  }

  &__content {
    margin-top: 15px;
  }

  &__wallet-status {
    font-size: 14px;
    opacity: 0.5;

    @include mixins.breakPoint(lg) {
      font-size: 18px;
    }
  }

  &__wallet-address {
    margin-top: 6px;
    font-size: 12px;

    @include mixins.breakPoint(lg) {
      font-size: 16px;
    }
  }

  &__error {
    padding-left: 15px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    font-size: 14px;
    color: colors.$color-red;

    @include mixins.breakPoint(lg) {
      padding-left: 23px;
      font-size: 18px;
    }
  }
}
