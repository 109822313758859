@use "../../../styles/constants.module.scss" as consts;

.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 166fr 201fr;
  gap: 12px;

  @media screen and (max-width: consts.$sidebar-breakpoint) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: consts.$mobile-breakpoint) {
    grid-template-columns: 1fr;

    >div:last-child {
      order: 1
    }

    >div:first-child {
      order: 2
    }
  }

  .profile_info_container {
    display: flex;
    width: 100%;
    height: 100%;
  }
}