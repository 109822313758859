@use "../../../../../styles/colors.module.scss" as colors;
@use "../../../../../styles/fonts-mixins.scss" as fonts;
@use "../../../../../styles/constants.module.scss" as consts;


$card_min_height: 177px;
$card_border_radius: 17px;
$card_expanded_height: 220px;

.h_fit {
  height: fit-content;
}


.container {
  height: fit-content;
  cursor: pointer;
  margin-bottom: 8px;
  width: calc((100% - 40px)/5);

  &.nft {
    .image {
      display: none;
    }

    .content_placeholder {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  @media screen and (max-width: 2240px) {
    width: calc((100% - 30px)/4);
  }

  @media screen and (max-width: 1600px) {
    width: calc((100% - 20px)/3);
  }

  @media screen and (max-width: 1155px) {
    width: calc((100% - 10px)/2);
  }

  @media screen and (max-width: consts.$mobile-breakpoint) {
    width: calc((100% - 12px)/3);
  }

  @media screen and (max-width: 520px) {
    width: calc((100% - 6px)/2);
  }

  .content {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 12px;
    width: 100%;
    overflow: hidden;
    padding: 0;
    background-color: transparent;

    &.isCurrent {
      color: colors.$color-white;
    }

    &.isSelected {

      .content_placeholder {
        border: 1px solid #B364D8;
      }

    }

    &.isMobile.isSelected {
      .image_placeholder {
        min-height: $card_expanded_height;
        transition: min-height 200ms ease;
      }

      .avatar_name {
        height: 0px;
        transition: height 200ms ease-out;
      }

      .content_placeholder {
        &::before {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 10px 9.5px 0 9.5px;
          border-color: #B364D8 transparent transparent transparent;
        }
      }

    }

    &.small {
      max-width: 144px;
      height: 190px;
    }
  }

  .content_placeholder {
    position: relative;
    padding-top: 12px;
    background: linear-gradient(148.47deg, rgba(217, 217, 217, 0.049) 29.79%, rgba(115, 115, 115, 0) 70.59%);
    border-radius: $card_border_radius;
    border: 1px solid rgba(255, 255, 255, 0.1);
    min-height: $card_min_height;
    width: 100%;
    max-width: 165px;
    display: flex;
    align-items: flex-end;
    transition: border-color 200ms ease-in;

  }

  .levelProgress {
    position: absolute;
    z-index: 1;
    top: 11px;
    left: 10px;

    &__value {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -72%);
      font-size: 15px;
      font-weight: 500;
      line-height: 15px;
      text-align: center;
      color: #FFFFFF;
    }

    &__label {
      position: absolute;
      left: 50%;
      top: 60%;
      transform: translate(-50%, 0%);
      font-size: 6px;
      font-weight: 500;
      line-height: 6px;
      text-align: center;
      color: #FFFFFF;
      opacity: 0.5;
    }
  }

  .current_label {
    position: absolute;
    z-index: 1;
    width: fit-content;
    margin: 0 auto;
    bottom: 10px;
    left: 0;
    right: 0;
    @include fonts.text-16;
    @include fonts.font-bold;
  }

  .price_label {
    position: absolute;
    width: fit-content;
    margin: 0 auto;
    bottom: 10px;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .price_label_text {
    @include fonts.text-16;
    @include fonts.font-bold;
  }

  .checked_icon {
    position: absolute;
    top: 11px;
    right: 10px;
  }

  .locked_icon {
    position: absolute;
    top: 11px;
    right: 10px;
  }

  .image_placeholder {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: $card_min_height;
    border-radius: $card_border_radius;
    overflow: hidden;
    transition: min-height 200ms ease;

    .image {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 140%;
      object-fit: contain;
      object-position: top;
      margin: auto;
      margin-bottom: 0;
      z-index: 0;
      transition: height 200ms ease 100ms;

      &.small {
        width: 100%;
        padding: 10px 10px 0;
      }

      &.grayscaled {
        filter: grayscale(1);
      }
    }
  }

  .avatar_name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 140px;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    margin-bottom: 8px;
    // height: 18px;
    transition: height 200ms ease-out 200ms;

    &__current {
      color: #B364D8;
    }

    &__locked {
      opacity: 0.4;
    }

    overflow: hidden;
  }

}