.label {

  display: flex;
  align-items: center;
  justify-content: center;

  width: 81px;
  height: 27px;
  min-height: 27px;
  padding: 4px 8px;

  background: #B364D8;

  border-radius: 10px;
  border: 1px solid #FFFFFF1A;

  font-size: 10px;
  font-weight: 500;
  line-height: 10px;

  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;

  overflow: hidden;

}