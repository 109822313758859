  .container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 38px 19px 19px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-end;
  }

  .display {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: calc((100vw - 98px) / 2);
    max-height: 205px;
    padding: 20px 20px 0;
    overflow: hidden;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    &.size-36 {
      span[class*=Digit] {
        font-size: 30px;
        line-height: 30px;
      }

      span[class*=Limiter] {
        font-size: 28px;
        line-height: 28px;
      }
    }

    svg {
      position: absolute;
      left: 0%;
      top: 0px;
      z-index: 1;
      max-height: 190%;
      max-width: 100%;

      &:first-child {
        z-index: 2;
      }
    }
  }

  .shine {
    position: absolute;
    left: 50%;
    bottom: -105px;
    transform: translateX(-50%);
    width: 400px;
    max-width: 100vw;
    height: 154px;
    border-radius: 50%;
    opacity: 0.7;
    background: radial-gradient(#B364D8 0%, transparent 70%, transparent 100%);
  }

  .bottomFade {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100vw;
    height: 35%;
    z-index: 2;
    background: linear-gradient(transparent 0%, #151517 70%, #151517 100%);
  }