.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column;
  gap: 8px;

  .field {
    width: 100%;
    height: 56px;
    min-height: 56px;
    padding: 8px 8px 8px 24px;
    gap: 10px;
    border-radius: 20px;

    background: #212123;
    border: 1px solid rgba(255, 255, 255, 0.1);


    &_withButton {
      padding-right: 75px;
    }
  }

  .button {
    position: absolute;
    right: 3px;
    top: 5px;
  }

  .iconButton {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0%, -50%);
  }
}