@use "../../../../../styles/constants.module.scss" as consts;

.boxItem {
  position: relative;
  min-height: 220px;
  max-height: 220px;
  background-color: #151517;
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
  transition: min-height 0.3s ease, background-color 0.3s ease;

  cursor: pointer;

  @media (max-width: consts.$mobile-breakpoint) {
    min-height: 382px;
  }

  @media (min-width: consts.$bp3xl) {
    min-height: 348px;
  }

  &:hover {
    background-color: #222223;
  }

  &.active {
    min-height: 350px;

    & .boxItem__includeIcon {
      background-image: url('../../../../../assets/images/StakininfoIcon.png');
    }

    & .gridContainer {
      opacity: 1;
    }

    @media(max-width: consts.$mobile-breakpoint) {
      min-height: 586px;
    }
  }

  &__content {
    position: relative;

    @media (max-width: consts.$mobile-breakpoint) {
      min-height: 382px;
    }
  }

  &__boxImage,
  &__boxImage_blured {
    position: absolute;
    left: -120px;
    top: -70px;
    width: 380px;
    aspect-ratio: 1/1;
    z-index: 10;
    transform: rotate(16deg);

    @media (max-width: 1334px) {
      // opacity: 0.2;
    }

    @media (max-width: consts.$mobile-breakpoint) {
      transform: rotate(0) translateX(-50%);
      bottom: -130px;
      top: initial;
      opacity: 1;
      left: 50%;
    }

    @media (min-width: consts.$bp3xl) {
      top: -130px;
      left: -160px;
      width: 610px;
    }
  }

  &__boxImage_blured {
    filter: blur(42px);

    @media (min-width: consts.$bp3xl) {
      filter: blur(125px);
    }
  }

  &__boxGradient {
    position: absolute;
    top: 33px;
    left: 0;
    transform: translateX(-45%);
    border-radius: 50%;
    width: 59%;
    opacity: 0.4;
    filter: blur(45px);
    aspect-ratio: 10/9;
    transition: all 0.4s linear;
    z-index: 1;

    &.expanded {
      aspect-ratio: 5/6;
    }

    @media (max-width: 1334px) {
      // opacity: 0.2;
    }

    @media (max-width: consts.$mobile-breakpoint) {
      opacity: 1;
      bottom: -160px;
      left: 55%;
      height: 480px;
      transform: translateX(-50%);
    }

    @media (min-width: consts.$bp3xl) {
      transform: translateX(-55%);
    }
  }

  &__descriptionBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 30px;
    padding-left: 40%;
    position: relative;
    height: 220px;
    z-index: 10;

    @media (max-width: 1334px) {
      padding-left: 30px;
      align-items: center;
    }

    @media (max-width: consts.$mobile-breakpoint) {
      align-items: flex-start;
    }

    @media (min-width: consts.$bp3xl) {
      height: 348px;
    }
  }

  &__nameLabel {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 4px;
    font-weight: 500;

    @media (min-width: consts.$bp3xl) {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 8px;
    }
  }

  &__name {
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 8px;
    font-weight: 500;

    @media (min-width: consts.$bp3xl) {
      font-size: 46px;
      line-height: 46px;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
    color: rgba(255, 255, 255, 0.5);

    @media (min-width: consts.$bp3xl) {
      font-size: 18px;
      line-height: 18px;
    }
  }

  &__include {
    display: flex;
    gap: 6px;
    align-items: center;
    color: #B364D8;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;

    &Icon {
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('../../../../../assets/images/StakininfoIcon2.png');
      width: 20px;
      height: 20px;
    }

    @media (min-width: consts.$bp3xl) {
      font-size: 20px;
      line-height: 20px;

      &Icon {
        width: 26px;
        height: 26px;
      }
    }
  }
}

.gridContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 154px);
  gap: 10px;
  padding-left: 40%;
  z-index: 10;
  transition: opacity 0.4s linear;

  @media (max-width: 1334px) {
    padding-left: 30px;
    justify-content: center;
  }

  @media (max-width: 1068px) {
    padding-inline: 10px;
  }

  @media (max-width: consts.$mobile-breakpoint) {
    opacity: 0;
  }
}

.gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 154px;
  height: 44px;
  background-color: #333;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.modalTitle {
  margin-bottom: 12px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #B364D8;
}

.modalDescription {
  padding: 15px 12px;
  background-color: #FFFFFF0D;
  border-radius: 14px;
}