@use "../../../../styles/colors.module.scss" as colors;
@use "../../../../styles/mixins.scss" as mixins;
@use "../../../../styles/constants.module.scss" as consts;

.referrals-copy {
  @include mixins.cardBase(20px);
  display: flex;
  flex-direction: column;
  background: linear-gradient(0, #5E3572 25%, #252527 60%);
  padding: 16px 16px 0;

  @include mixins.breakPoint(xl) {
    overflow: hidden;
  }

  &__decor {
    padding-top: 30px;
    margin: auto auto 0;

    //@include mixins.breakPoint(sb) {
    //  padding-top: 60px;
    //}
  }

  &__link {
    margin-top: 24px;

    @include mixins.breakPoint(xl) {
      margin-top: 36px;
    }
  }
}
