@use "../../styles/constants.module.scss" as consts;

.walletSection {
  .addressWrapper {
    cursor: pointer;
    width: 100%;
    background-color: #151623;

    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    padding: 10px 18px;

    @media (max-width: consts.$mobile-breakpoint) {
      padding: 8px 7px;
      font-size: 12px;
      line-height: 18px;
    }

    color: #b364d8;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;
  }

  .addressBody {
    max-width: 230px;

    @media (max-width: consts.$mobile-breakpoint) {
      width: 146px;
    }

    width: 100%;
    position: relative;

    .walletDetails {
      position: absolute;
      z-index: 2;
      bottom: -170px;
      right: 0;
      width: 318px;
      height: 150px;

      .walletDetailsBody {
        width: 100%;
        height: 100%;
        background-color: #151623;
        padding: 16px 24px;

        .addressText {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;

          span {
            &:first-child {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              opacity: 0.6;
            }

            &:last-child {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }

        .disconnectBtn {
          position: relative;
          width: 100%;
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          color: #ff3d3d;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 42px;

          .disconnectBorders {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }
}

section.modalContent {
  max-width: initial;
  width: fit-content;
  max-width: 100vw;

  @media (max-width: consts.$mobile-breakpoint) {}

  [class*=cardsWrapper] {

    @media (max-width: consts.$mobile-breakpoint) {
      height: auto;
    }
  }
}

.modalWrapper {
  width: 680px;

  @media (max-width: consts.$mobile-breakpoint) {
    width: 100%;
  }

  .modalSubtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    text-align: left;
    color: #a1a2a7;
    margin-bottom: 16px;
  }

  .connectorsList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 22px;
    height: 440px;
    overflow: auto;

    @media (max-width: consts.$mobile-breakpoint) {
      height: initial;
      flex-flow: column nowrap;
      height: 100%;
      gap: 5px;
    }


    .connectorItemBody {
      position: relative;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 100%;
      max-width: 297px;
      height: 299px;
      padding: 34px 27px 25px;

      border-radius: 25px;
      border: 1px solid #1D1D20;
      background: #151517;

      @media (max-width: consts.$mobile-breakpoint) {
        height: 114px;
        width: 297px;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 19px;
        gap: 18px;

      }


      cursor: pointer;
      transition: all 0.2s linear;


      &:hover {
        background-color: #1c1c1f;
      }

      .reward {
        position: absolute;
        top: 5px;
        right: 10px;
        color: #b364d8;
      }

      .connectorIconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 126px;
        height: 126px;
        min-width: 126px;
        min-height: 126px;
        border-radius: 34px;
        background: linear-gradient(148.47deg, rgba(217, 217, 217, 0.05) 29.79%, rgba(115, 115, 115, 0) 70.59%);
        border: 3.15px solid #2e2e2e;
        margin: 0 auto;

        @media (max-width: consts.$mobile-breakpoint) {
          width: 74px;
          height: 74px;
          min-width: 74px;
          min-height: 74px;
          border-radius: 20px;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }


        &__icon {
          display: block;
          margin: 0 auto;
          max-width: 54px;
          max-height: 54px;

          @media (max-width: consts.$mobile-breakpoint) {
            max-width: 31px;
            max-height: 31px;
          }
        }
      }

      .connectWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;


        @media (max-width: consts.$mobile-breakpoint) {
          gap: 8px;
        }

        .connectorTitle {
          font-size: 28px;
          font-weight: 500;
          line-height: 28px;
          text-align: left;

          @media (max-width: consts.$mobile-breakpoint) {
            font-size: 22px;
            line-height: 22px;
          }
        }

        .connectText {
          font-size: 15px;
          font-weight: 500;
          line-height: 15px;
          text-align: left;
          text-transform: uppercase;
          color: #B364D8;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;

          @media (max-width: consts.$mobile-breakpoint) {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    }
  }
}