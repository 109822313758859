@use "../../../../../styles/constants.module.scss" as consts;
@use "../../../../../styles/colors.module.scss" as colors;
@use "../../../../../styles/fonts-mixins.scss" as fonts;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  // display: flex;
  // flex-direction: column;
  // height: 100%;
  // min-height: fit-content;
  // padding: 10px 10px 0;
  // background-color: colors.$border_color;

  .card {
    height: auto;
    padding: 39px 32px 29px 33px;
  }

  .avatar {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    img {
      width: 60%;
      height: 100%;
      object-fit: scale-down;
    }

    &.nft img {
      width: 90%;
      height: initial;
      object-fit: cover;
      border-radius: 40px;
      border: 2px solid rgba(131, 217, 135, 0.5);
    }

    .shine {
      position: absolute;
      left: 50%;
      bottom: -105px;
      transform: translateX(-50%);
      width: 400px;
      max-width: 100vw;
      height: 154px;
      border-radius: 50%;
      opacity: 0.7;
      background: radial-gradient(rgba(131, 217, 135, 0.55) 0%, transparent 70%, transparent 100%);
    }
  }

  .name {
    font-size: 34px;
    font-weight: 500;
    line-height: 34px;
    text-align: left;
    width: 100%;
    margin-bottom: 9px;
  }

  .helper_text {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #FFFFFF66;
    margin-bottom: 34px;
  }

  .description {
    width: 100%;
    margin-bottom: 34px;
    max-height: 100px;

    color: rgba(255, 255, 255, .6);
    line-height: 28px;
    overflow: visible;
    display: block;
    line-height: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: auto;


    @media screen and (max-width: 1536px) {
      -webkit-box-orient: horizontal;
      -webkit-line-clamp: none;
    }
  }

  .btn {
    display: flex;
    align-items: flex-end;
    flex: 1 1;

    .text {
      color: colors.$color-green;
    }
  }

}