  @use "../../../../styles/mixins.scss" as mixins;

  @keyframes slideDown {
    0% {
      top: 100%;
      opacity: 0;
    }
    100% {
      top: calc(100% + 7px);
      opacity: 1;
    }
  }

  .mini-profile {
    position: relative;
    display: flex;
    align-items: center;
    @include mixins.cardBase(25px);
    padding: 9px 11px;

    @include mixins.breakPoint(sb) {
      padding: 10px 11px;
    }

    &__progress {
      position: relative;
      z-index: 2;
    }

    &__wrapper {
      flex-grow: 1;
    }

    &__user-info {
      flex-grow: 1;
      position: relative;
      z-index: 1;
      margin-left: -5px;
    }

    &__dropdown-menu {
      display: none;
      position: absolute;
      top: calc(100% + 7px);
      right: 0;
      width: 100%;
      animation: slideDown .15s;

      &--show {
        display: block;
      }
    }
  }
