@use "../../../../styles/mixins" as mixins;

.wrapper {
  width: 100%;
  max-width: calc(100vw - 16px);
  height: 100%;
  padding: 1px;
  border-radius: 25px;
  background: linear-gradient(155.03deg, #1D1D20 23.72%, rgba(43, 44, 48, 0) 95.34%);
  opacity: 1;
  animation: fadeIn 100ms linear;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }


  &_inner {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    width: 100%;
    height: 100%;
    padding: 32px 16px 24px;
    border-radius: 25px;
    background: #151517;
    overflow: visible;

    @include mixins.breakPoint(sm) {
      padding: 0 32px 40px;
      overflow: hidden;
      gap: 24px;
    }
  }

  &_roulette {
    position: relative;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;

    @include mixins.breakPoint(sm) {
      height: 100%;
    }

    &_info {
      position: relative;
      z-index: 5;
      width: 100%;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @include mixins.breakPoint(sm) {
        position: absolute;
      }

      h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        flex: 1;
      }

      &_price {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        flex: 1;

        img {
          width: 23px;
          min-width: 23px;
        }

        span {
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: #2997F8;
        }
      }
    }
  }

  &_buttons {
    display: flex;
    gap: 10px;
    align-items: center;

    button:first-child {
      flex-shrink: 4;
    }

    button:last-child {
      flex-grow: 4;
    }
  }
}

.roulette_wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1;

  &.mobile {
    height: initial;
    overflow: visible;
    width: calc(100% + 2* 16px);
    transform: translateX(-16px);
    margin-bottom: 24px;

    ul {
      display: flex;
      position: relative;
      // transition: all 300ms ease;
      display: flex;
      flex-flow: row nowrap;

      li {
        position: relative;
        min-width: 85%;
        transition-property: all;
        transition-timing-function: linear;
        // transition-duration: 150ms;
        transform: translateX(0%);

        &.hideLeft {
          min-width: 0;
        }

        &:nth-child(1) {}

        &:nth-child(2) {}

        &:nth-child(3) {}

        &:nth-child(4) {}

        &:nth-child(5) {}

        &:nth-child(6) {}

        &:nth-child(7) {}
      }
    }

    &::before,
    &::after {
      width: 43px;
      height: 100%;
      top: 0;
      bottom: initial;
    }

    &::before {
      left: -9px;
      right: initial;
      background: linear-gradient(90deg, #151517 0%, rgba(21, 21, 23, 0.59) 30%, rgba(21, 21, 23, 0) 100%);
    }

    &::after {
      right: -9px;
      left: initial;
      background: linear-gradient(90deg, rgba(21, 21, 23, 0) 0%, rgba(21, 21, 23, 0.59) 70%, #151517 100%);
    }
  }

  &.roulette:not(.mobile) {
    ul {
      position: absolute;
      display: grid;
      grid-template-rows: auto;

      li {
        position: absolute;
        left: 0%;
        top: 70%;
        z-index: 1;
        opacity: 0;
        transition: all 10ms linear;
        transform: scale(0.1) translate(0%, -50%);

        &.zero,
        &:nth-child(1) {
          z-index: 2;
          opacity: 0;
          top: 0%;
          transform: scale(0.2) translate(0%, -100%);
        }

        &.first,
        &:nth-child(2) {
          z-index: 3;
          opacity: 1;
          top: 10%;
          transform: scale(0.5) translate(0%, -50%);
        }

        &.second,
        &:nth-child(3) {
          z-index: 4;
          opacity: 1;
          top: 30%;
          transform: scale(0.8) translate(0%, -50%);
        }

        &.third,
        &:nth-child(4) {
          z-index: 5;
          opacity: 1;
          top: 50%;
          transform: scale(1) translate(0%, -50%);
        }

        &.fourth,
        &:nth-child(5) {
          z-index: 4;
          opacity: 1;
          top: 62%;
          transform: scale(0.8) translate(0%, -50%);
        }

        &.fifth,
        &:nth-child(6) {
          z-index: 3;
          opacity: 1;
          top: 69%;
          transform: scale(0.5) translate(0%, -50%);
        }

        &.sixth,
        &:nth-child(7) {
          z-index: 2;
          opacity: 0;
          top: 75%;
          transform: scale(0.2) translate(0%, -50%);
        }

      }
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 4;
    left: 0;
    width: 100%;
    height: 110px;
  }

  &:before {
    top: 0;
    background: linear-gradient(180deg, #151517 0%, rgba(21, 21, 23, 0.59) 41.98%, rgba(21, 21, 23, 0) 100%);
  }

  &:after {
    bottom: 0px;
    background: linear-gradient(180deg, rgba(21, 21, 23, 0) 0%, rgba(21, 21, 23, 0.59) 41.98%, #151517 100%);
  }

  ul {
    position: absolute;
    width: 100%;
    height: 100%;

    &.animated {
      transition: top linear 3000ms, left linear 3000ms;
    }

    li {
      position: relative;
      z-index: 0;
      width: 100%;
      padding: 8px;
      transition: transform 3s ease-in-out;

      &.active {
        z-index: 1;
        transform: scale(1);
      }

      img {
        width: 100%;
        border: 3px solid #3D3D3D;
        border-radius: 40px;
      }
    }
  }
}
