@use "../../../../styles/constants.module" as consts;
@use "../../../../styles/colors.module" as colors;
@use "../../../../styles/mixins" as mixins;

.setup-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  &__body {
    padding: 40px 0 45px;

    .checkbox-field {
      margin-top: 22px;
      padding-left: 12px;

      @include mixins.breakPoint(xl) {
        padding-left: 20px;
      }
    }
  }

  &__footer {
    button {
      &:last-of-type {
        margin-top: 10px;
      }
    }
  }
}
