@use "../../styles/constants.module" as consts;
@use "../../styles/colors.module" as colors;
@use "../../styles/mixins" as mixins;

.input-field {
  $this: &;

  &__label {
    display: block;
    margin-bottom: 8px;
    padding-left: 12px;
    width: fit-content;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }

  &__wrapper {
    display: block;
    position: relative;
  }

  &__container {
    display: inherit;
    height: 56px;
  }

  &__input {
    display: block;
    font-family: consts.$main-font-family;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    height: 100%;
    background-color: colors.$new-color-gray-light;
    padding: 0 24px;
    border-radius: 20px;
    border: 1px solid rgba(153, 153, 153, 0.1);

    @include mixins.textEllipsis;

    @include mixins.placeholder {
      color: colors.$color-white;
      opacity: .4;
    }

    @include mixins.breakPoint(xl) {
      padding: 0 16px;
      font-size: 16px;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  &__addon-left,
  &__addon-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;

    svg {
      width: 18px;
      height: 18px;

      @include mixins.breakPoint(xl) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__addon-left {
    left: 12px;

    @include mixins.breakPoint(xl) {
      left: 16px;
    }
  }

  &__addon-right {
    right: 12px;

    @include mixins.breakPoint(xl) {
      right: 16px;
    }
  }

  &__message {
    display: block;
    margin-top: 10px;
    padding-left: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  &--addon-left {
    #{$this}__field {
      padding-left: 36px;

      @include mixins.breakPoint(xl) {
        padding-left: 46px;
      }
    }
  }

  &--addon-right {
    #{$this}__field {
      padding-right: 36px;

      @include mixins.breakPoint(xl) {
        padding-right: 46px;
      }
    }
  }

  &--error {
    #{$this}__input {
      border-color: colors.$color-red;
    }

    #{$this}__message {
      color: colors.$color-red;
    }
  }

  &--success {
    #{$this}__message {
      color: colors.$color-green;
    }
  }

  &--warning {
    #{$this}__message {
      color: colors.$color-yellow;
    }
  }

  &--loading,
  &--readonly {
    #{$this}__field {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &--focused:not(#{$this}--readonly):not(#{$this}--disabled):not(#{$this}--invalid) {
    #{$this}__field {
      @include mixins.placeholderHidden;
    }
  }

  &--disabled {
    #{$this}__wrapper {
      opacity: 0.4;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    font-style: italic;
  }
}
