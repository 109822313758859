@use "../../../../styles/mixins.scss" as mixins;
@use "../../../../styles/colors.module.scss" as colors;
@use "../../../../styles/constants.module.scss" as consts;

.social {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &__item {
    transition: color consts.$transition-time;

    @include mixins.breakPoint(sb) {
      &:hover {
        color: colors.$color-purple;
      }
    }
  }
}
