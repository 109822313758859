@use "../../../../../styles/constants.module.scss" as consts;
@use "../../../../../styles/colors.module.scss" as colors;
@use "../../../../../styles/fonts-mixins.scss" as fonts;

.centered_loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reactions {
  overflow: hidden;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    width: calc(100vw - 66px);
  }
}

.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: consts.$mobile-breakpoint) {
    gap: 8.5px;
  }

  .item_container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 13px;
    max-width: 85px;
    min-width: 85px;
    max-height: 44px;
    min-height: 44px;

    background: linear-gradient(148.47deg, rgba(217, 217, 217, 0.1) 29.79%, rgba(115, 115, 115, 0) 70.59%);
    border-radius: 10px;
    border: 1px solid rgb(255 255 255 / 20%);

    transition: border-color 150ms ease;

    &.reacted {
      border-color: #B364D8;
    }

    @media screen and (max-width: 1220px) {
      max-width: 85px;
      max-height: 44px;
    }

    @media screen and (max-width: 568px) {
      max-width: 85px;
      max-height: 44px;
      min-width: 85px;
      min-height: 44px;
    }

    img {
      max-width: 32px;
    }

    .chip {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: fit-content;
      min-width: 24px;

      color: rgb(255, 255, 255);
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      text-align: center;
      @include fonts.font-bold;

    }
  }
}