.static {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &.vertical {
    flex-flow: column nowrap;

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 4;
      left: 0;
      width: 100%;
      height: 110px;
    }

    &:before {
      top: 0;
      background: linear-gradient(180deg, #151517 0%, rgba(21, 21, 23, 0.59) 41.98%, rgba(21, 21, 23, 0) 100%);
    }

    &:after {
      bottom: 0px;
      background: linear-gradient(180deg, rgba(21, 21, 23, 0) 0%, rgba(21, 21, 23, 0.59) 41.98%, #151517 100%);
    }

    .slides {
      flex-flow: column nowrap;
      height: 100%;

      .slide {
        img {
          width: 100%;
        }
      }
    }
  }

  &.horizontal {
    flex-flow: row nowrap;
    width: 100vw;
    transform: translateX(-26px);

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 43px;
      height: 100%;
      top: 0;
      bottom: initial;
    }

    &::before {
      left: 0;
      right: initial;
      background: linear-gradient(90deg, #151517 0%, rgba(21, 21, 23, 0.59) 30%, rgba(21, 21, 23, 0) 100%);
    }

    &::after {
      right: 0;
      left: initial;
      background: linear-gradient(90deg, rgba(21, 21, 23, 0) 0%, rgba(21, 21, 23, 0.59) 70%, #151517 100%);
    }

    .slides {
      flex-flow: row nowrap;
      position: relative;
      width: 100%;

      .slide {
        min-width: 85vw;
        max-height: 85vw;

        img {
          height: 100%;
        }
      }
    }
  }

  .slides {
    position: absolute;
    display: flex;

    &.animate {
      transition-timing-function: linear;
      transition-property: top, left;
    }

    .slide {
      position: relative;
      z-index: 0;
      width: 100%;
      padding: 8px;

      img {
        border: 3px solid #3D3D3D;
        border-radius: 40px;
        aspect-ratio: 1/1;
      }
    }
  }
}