.shine_wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1/1;
  width: 300px;

  .container1,
  .container2,
  .container3 {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 115%;
  }

  .container1 {
    transform: translate(-50%, -50%) rotate(0deg);
    animation: rotateContainer random(20) + 60s infinite normal linear;
  }

  .container2 {
    transform: translate(-50%, -50%) rotate(360deg);
    animation: rotateReverseContainer random(20) + 60s infinite normal linear;
  }

  .container3 {
    transform: translate(-50%, -50%) rotate(0deg);
    animation: rotateContainer random(20) + 60s infinite normal linear;
  }

  .particle1,
  .particle2,
  .particle3 {
    list-style-type: none;
    position: absolute;
    transform-origin: top;
    height: random(40)+70%;
    background: radial-gradient(ellipse at top, #d6a1ef 0%, #d6a1ef 25%, #B364D8 40%, transparent 70%, transparent 100%);
    top: 50%;
    left: 50%;
  }

  .particle1 {
    @for $i from 0 through 30 {
      &:nth-child(#{$i}) {
        width: random(40) + px;
        transform: translate(-50%, 0%) rotate(random(360) + deg);
        // animation: grow random(5) + 20s infinite normal ease-in-out;

      }
    }
  }

  .particle2 {

    @for $i from 0 through 30 {
      &:nth-child(#{$i}) {
        width: random(40) + 20px;
        transform: translate(-50%, 0%) rotate(random(360) + deg);
        // animation: grow random(5) + 20s infinite normal ease-in-out;

      }
    }
  }

  .particle3 {

    @for $i from 0 through 30 {
      &:nth-child(#{$i}) {
        width: random(80) + 100px;
        transform: translate(-50%, 0%) rotate(random(360) + deg);
        // animation: shrink random(5) + 20s infinite normal ease-in-out;


      }
    }
  }

  @keyframes grow {
    0% {
      height: 115%;
    }

    50% {
      height: 105%;
    }

    100% {
      height: 115%;
    }
  }

  @keyframes shrink {
    0% {
      height: 117%;
    }

    50% {
      height: 105%;
    }

    100% {
      height: 117%;
    }
  }

  @keyframes rotateContainer {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    50% {
      transform: translate(-50%, -50%) rotate(180deg);
    }

    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes rotateReverseContainer {
    0% {
      transform: translate(-50%, -50%) rotate(360deg);
    }

    50% {
      transform: translate(-50%, -50%) rotate(180deg);
    }

    100% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }
}