@use "../../../../../../styles/colors.module" as colors;
@use "../../../../../../styles/fonts-mixins" as fonts;
@use "../../../../../../styles/constants.module" as consts;
@use "../../../../../../styles/mixins.scss" as mixins;


.navigation-item {
  $this: &;

  display: flex;
  align-items: center;
  column-gap: 12px;
  transition: color consts.$transition-time;
  flex-grow: 1;

  @include mixins.breakPoint(sb) {
    &:hover {
      color: colors.$color-purple;
    }
  }

  &:active {
    color: colors.$color-purple;
  }

  &--selected {
    color: colors.$color-purple;

    #{$this}__icon {
      color: colors.$color-purple;
    }
  }

  &--disabled {
    @include mixins.breakPoint(sb) {
      &:hover {
        color: inherit;
      }
    }

    cursor: not-allowed;

    #{$this}__label, svg {
      opacity: 0.4;
    }
  }

  &__icon {
    flex-shrink: 0;
  }

  &__label {
    font-family: Rubik, sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;

    @include mixins.breakPoint(sb) {
      font-size: 16px;
    }
  }

  &__chip {
    display: block;
    color: #000000;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    padding: 6px 9px;
    border-radius: 38px;
    background-color: colors.$color-purple;
  }
}
