@use "../constants.module.scss" as consts;

.app_logo {
  width: 150px;
  height: 54px;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    width: 110px;
    height: 40px;
  }
}