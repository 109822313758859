@use "../../../styles/mixins" as mixins;

.not-auth-aside-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @include mixins.breakPoint(xl) {
    margin-bottom: 51px;
  }
}
