@use "../../../../../styles/mixins" as mixins;

div:last-child.wrapper {
  background-image: url("../../../../../assets/images/nft-info-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: initial;
  overflow: hidden;


  .title {
    position: relative;
    z-index: 2;
    font-size: 26px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    margin-bottom: 40px;

    @include mixins.breakPoint(sm) {
      font-size: 32px;
      font-weight: 500;
      line-height: 32px;
    }

  }

  .nft {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 50%;
    min-width: 260px;
    margin: 0 auto;

    @include mixins.breakPoint(sm) {
      min-width: 307px;
    }

    >img {
      position: relative;
      z-index: 2;

      &.bordered {
        border: 3px solid #3D3D3D;
        border-radius: 40px;
      }
    }

    [class*="rarity"] {
      position: relative;
      z-index: 2;
      transform: translateY(-50%);
    }

    .empty {
      height: 34px;
    }

    +button {
      position: relative;
      z-index: 2;
      width: 340px;
      margin: 0 auto;
    }
  }

}
