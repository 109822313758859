@use "src/styles/constants.module.scss" as consts;
@use "src/styles/mixins.scss" as mixins;

.betaCodeModalContent {
  max-width: 630px;

  [class*=cardsWrapper] {
    @media (max-width: consts.$mobile-breakpoint) {
      height: auto;
    }

    padding: 15px 15px 20px;

    @include mixins.breakPoint(lg) {
      padding: 40px 32px;
    }
  }
}
