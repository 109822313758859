@use "src/styles/mixins" as mixins;

.betaSkeleton {
  margin-top: 8px;
  min-height: 97px;

  @include mixins.skeleton(97px, 100%, 18px);

  @include mixins.breakPoint(sb) {
    min-height: 91px;
    @include mixins.skeleton(91px, 100%, 23px);
  }
}
