@use "../../styles/mixins" as mixins;

.logo {
  width: 109px;

  @include mixins.breakPoint(xl) {
    width: 147px;
  }

  svg {
    width: 100%;
    height: auto;
  }
}
