@use "../../../../styles/constants.module.scss" as consts;

.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 25px;
  overflow: hidden;
  padding: 16px;
  height: 100%;

  background-color: #242424;
  background-image: url("../../../../assets/images/pet_bg_green.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    grid-template-columns: 1fr;
    padding: 9px;
  }


  &.hoverActive {
    &:hover {
      .mask {
        color: #FFFFFF;
        fill: #FFFFFF;
        background-color: rgba(0, 0, 0, 0.4);
        cursor: pointer;

        svg path {
          fill: #FFFFFF;
        }
      }

      .centered {
        color: #FFFFFF;
        fill: #FFFFFF;
        cursor: pointer;

        svg path {
          fill: #FFFFFF;
        }
      }
    }
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 2;
    color: rgba(255, 255, 255, 0);
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 300ms ease, color 300ms ease;

    svg path {
      fill: rgba(255, 255, 255, 0);
      transition: fill 300ms ease;
    }
  }

  .centered {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 2;
    color: rgba(255, 255, 255, 0);
    transition: color 300ms ease;

    svg path {
      fill: rgba(255, 255, 255, 0);
      transition: fill 300ms ease;
    }
  }

  .avatar_container .mask {
    background-color: transparent;
  }

}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
}

.loader_container {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 24px;

  .shine {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    width: 335px;
    height: 527px;
    border-radius: 50%;
    background-color: rgba(131, 217, 135, 0.44);
    filter: blur(100px);
  }

  img {
    position: relative;
    z-index: 1;
    max-height: 100%;
    max-width: 100%;
    width: 90%;
    object-fit: contain;
  }
}

.info_container {
  position: relative;
  padding: 24px;
  z-index: 3;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .petname {
    font-size: 34px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;

    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      opacity: 0.6;
    }
  }

  .levelProgress {
    position: relative;
    height: 58px;
    width: 58px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    p {
      font-size: 20px;
      line-height: 20px;

      span {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }

  .editIcon {
    cursor: pointer;
  }


  .petsDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    text-align: left;
    opacity: 0.6;
  }

  .petActions {
    display: flex;
    gap: 8px;
    align-items: center;

    button:first-child {
      flex-shrink: 2;
    }
  }
}