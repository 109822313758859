@use "../../styles/colors.module.scss" as colors;
@use "../../styles/mixins.scss" as mixins;
@use "../../styles/constants.module.scss" as consts;

.tasks {
  //padding: 23px 14px 39px;
  height: 100%;
  width: 100%;
  //background-color: colors.$new_background_main;
  //@include mixins.cardBase(25px);
  //overflow: hidden;

  //@include mixins.breakPoint(lg) {
  //  padding: 23px 19px;
  //}

  &__loader {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //&__content {
  //  height: 100%;
  //  width: 100%;
  //  overflow-y: auto;
  //  @include mixins.customScrollbar;
  //}

  &__lists {
    @include mixins.breakPoint(xl) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 8px;
      overflow: hidden;
    }
  }

  &__list {
    @include mixins.breakPoint(xl) {
      display: flex;
      flex-direction: column;
      @include mixins.cardBase(25px);
      padding: 24px;
    }
  }

  &__list-header {
    display: none;

    @include mixins.breakPoint(xl) {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      column-gap: 20px;
    }
  }

  &__list-counter {
    @include mixins.breakPoint(xl) {
      margin-left: auto;

      strong {
        font-weight: 500;
        color: colors.$color-green
      }
    }
  }
}
