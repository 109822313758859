@use "../../styles/constants.module" as consts;
@use "../../styles/mixins" as mixins;
@use "../../styles/colors.module" as colors;

.title {
  font-family: consts.$main-font-family;
  font-weight: 500;
  color: colors.$color-white;

  &--xl {
    font-size: 34px;
    line-height: 34px;

    @include mixins.breakPoint(xl) {
      font-size: 54px;
      line-height: 54px;
    }
  }

  &--l {
    font-size: 20px;
    line-height: 24px;

    @include mixins.breakPoint(xl) {
      font-size: 34px;
      line-height: 34px;
    }
  }

  &--l-fixed {
    font-size: 20px;
    line-height: 24px;
  }

  &--s {
    font-size: 14px;
    line-height: 14px;

    @include mixins.breakPoint(xl) {
      font-size: 16px;
      line-height: 16px;
    }
  }

  &--uppercased {
    text-transform: uppercase;
  }
}
