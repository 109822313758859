@use "../../../../../styles/constants.module" as consts;
@use "../../../../../styles/colors.module" as colors;
@use "../../../../../styles/mixins.scss" as mixins;

.dropdown-menu {
  @include mixins.cardBase(23px);
  padding: 24px 15px 16px;
  z-index: 29;

  &__wallet-header {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: 16px;
    color: transparentize(colors.$color-white, .4);
    margin-bottom: 24px;
    cursor: pointer;
    transition: color consts.$transition-time;

    @include mixins.breakPoint(xl) {
      &:hover {
        color: colors.$color-white;
      }
    };

    svg {
      transform: rotate(180deg);
    }
  }

  &__wallet-container {
    display: flex;
    justify-content: space-between;
  }

  &__wallet-label,
  &__wallet-address {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &__wallet-label {
    font-size: 14px;
    font-weight: 500;

    &--gray {
      color: transparentize(colors.$color-white, .4);
    }

    &--big {
      font-size: 16px;
    }
  }

  &__wallet-connect-button {
    font-size: 14px;
    transition: color consts.$transition-time;

    @include mixins.breakPoint(xl) {
      &:hover {
        color: colors.$color-purple;
      }
    };
  }

  &__wallet-address {
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__button {
    margin-top: 16px;
  }
}
