@use "../../../../../styles/constants.module.scss" as consts;

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1;

  &.isModal {
    padding: 0 6px 12px;
    width: calc(100vw - 40px);
  }

  &.notModal {
    @media screen and (max-width: consts.$mobile-breakpoint) {
      display: none;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;

    @media screen and (max-width: consts.$mobile-breakpoint) {
      margin-top: 4px;
      margin-bottom: 12px;
    }

    .points_label {
      display: none;

      @media screen and (max-width: consts.$mobile-breakpoint) {
        display: block;
      }
    }
  }

  .list_placeholder {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    height: fit-content;
    padding-bottom: 12px;

    @media screen and (max-width: 1536px) {
      gap: 6px;
    }

    &.isInModal {
      grid-template-columns: repeat(auto-fill, 144px);
    }

    &.isNotModal {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    &.center {
      justify-content: center;
    }
  }

  .buttons {
    display: flex;
    gap: 16px;

    &.isModalBtns {
      width: 100%;
      flex-direction: row;
    }

  }

  .mobile_avatar_info {
    width: 100%;
    height: 100%;

    h3 {
      display: block;
      font-family: Rubik;
      font-size: 32px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      margin-bottom: 7px;
    }

    p {
      display: block;
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      opacity: 0.6;
      margin-bottom: 17px;
      max-height: 82px;
      overflow: auto;
    }

    span {
      display: block;
      font-family: Rubik;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      text-align: left;
      color: #FFFFFF66;
      margin-bottom: 22px;
    }
  }

  @keyframes grow {
    from {
      height: 0;
    }

    to {
      height: 232px;
    }
  }

  @keyframes pseudogrow {
    from {
      height: 232px;
    }

    to {
      height: 100%;
    }
  }
}