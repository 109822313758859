@import "../../../styles/colors.module.scss";

.modalRoot {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBackdrop {
}

@keyframes accordion {
  from {
    max-width: 0;
  }

  to {
    max-width: 100%;
  }
}

.modalContent {
  max-width: 680px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;

  @media (max-width: 768px) {
    padding: 10px;
  }

  .titleWrapper {
    width: 100%;
    padding: 20px 23px;
    background: linear-gradient(90deg, #212123 51.12%, #151517 100%);
    border: 1px solid #2B2B30;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
      background: #151517;
      border: 1px solid #1D1D20;
      margin-top: 50px;
    }

    .gradient {
      position: absolute;
      bottom: 0;
      left: 0;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      @media (max-width: 768px) {
        justify-content: space-between;
        flex: 1;
      }

      .backIcon {
        @media (min-width: 768px) {
          display: none;
        }
      }

      .divider {
        width: 6px;
        height: 33px;
        border-radius: 11px;
        border: none;
        background-color: $color-purple;

        @media (max-width: 768px) {
          display: none;
        }
      }

      .titleText {
        font-size: 36px;
        font-weight: 700;
        line-height: 46.44px;
        text-align: left;

        @media (max-width: 768px) {
          flex: 1;
          font-size: 20px;
          font-weight: 500;
          line-height: 25.8px;
          text-align: center;
          transform: translateX(-24px);
        }
      }

      .subtitleText {
        font-size: 16px;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      gap: 18px;
      align-items: center;

      @media (max-width: 768px) {
        display: none;
      }

      .stackedLabel {
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        text-transform: uppercase;
      }

      .stackedValue {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        color: $color-purple;

        .tkImg {
          width: 24px;
          height: 24px;
        }
      }

      .closeIcon {
        cursor: pointer;
        display: inline-block;
        margin: 16px;
      }
    }
  }

  .cardsWrapper {
    background: #151517;
    border: 1px solid #2B2B30;
    border-radius: 24px;
    padding: 24px 32px 40px;
    animation: accordion 0.6s forwards;

    @media (max-width: 768px) {
      padding: 20px 16px;

      height: 76vh;
    }

    .btnsWrapper {
      margin-top: 30px;
      padding: 0 32px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      align-items: center;

      @media (max-width: 768px) {
        display: none;
      }

      .btnsContentWrapper {
        display: flex;
        max-width: 350px;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }

      .bottomDetails {
        .totalWins {
          font-size: 16px;
          font-weight: 500;
          line-height: 32px;

          b {
            color: $color-purple;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            padding-left: 10px;
          }
        }
      }

      .prevBtn,
      .nextBtn {
        text-transform: uppercase;
        background-color: #2C2C2E;
        border-radius: 18px;
        border: 1px solid #3F3F40;
        max-width: 170px;
        width: 100%;
        height: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;

        .arrow {
          display: inline-block;
          margin-right: 16px;
        }
      }

      .nextBtn {
        .arrow {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.stakeCardWrapper {
  padding: 24px 30px;
  background-color: #212123;
  border-radius: 25px;
  width: 500px;
  height: 329px;
  overflow: hidden;
  perspective: 1000px;
  /* Додає перспективу для перевертання */
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    height: 116px;
    padding: 15px;
    border: 1px solid #2B2B30;
    border-radius: 17px;
    margin-bottom: 8px;
  }

  .itemGradient {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .itemGradientBlue {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .looseImg {
    position: absolute;
    bottom: -20px;
    right: -20px;
  }

  .winnerImg {
    width: 250px;
    height: 250px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .stakeCardFront,
  .stakeCardBack {
    width: 90%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    transition: transform 0.6s;
    transform-style: preserve-3d;

    @media (max-width: 768px) {
      width: 94% !important;
      height: 170px;
    }
  }

  .stakeCardFront {
    transform: rotateY(0deg);
  }

  .stakeCardBack {
    transform: rotateY(180deg);
  }

  &.stakeCardWrapperFlipped {
    @media (max-width: 768px) {
      height: 307px;
    }

    .stakeCardFront {
      transform: rotateY(-180deg);
    }

    .stakeCardBack {
      transform: rotateY(0deg);
    }
  }

  .stackingDate {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    opacity: 0.4;

    @media (max-width: 768px) {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .stakeAmount {
    font-size: 48px;
    font-weight: 500;
    line-height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-purple;

    @media (max-width: 768px) {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
    }

    span {
      padding-right: 12px;
    }

    img {
      width: 48px;
      height: 48px;

      @media (max-width: 768px) {
        width: 29px;
        height: 29px;
      }
    }
  }

  .stakePerDay {
    opacity: 0.6;

    @media (max-width: 768px) {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;

      b,
      span {
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 14px !important;
      }
    }

    b {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .stakeCardFront {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;

    height: 285px;

    @media (max-width: 768px) {
      height: 90px;
    }

    .stackingTop {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .unstakeBtn {
        padding: 12px 16px;
        border-radius: 14px;
        border: 1px solid #373739;
        color: #FF3D3D;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;

        @media (max-width: 768px) {
          font-size: 10px;
          font-weight: 500;
          line-height: 10px;
          padding: 8px 17px;
          border-radius: 10px;
        }
      }
    }

    .stackingBottom {
      flex-direction: column;
      gap: 9px;
    }
  }

  .stakeCardBack {
    .stakeCardBackHeader {
      min-height: 54px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;

      @media (max-width: 768px) {
        padding-bottom: 22px;
      }

      .stackingDate {
      }

      .unstakeBtn {
        transition: all 0.2s linear;
        padding: 8px 14px;
        border-radius: 14px;
        border: 1px solid #373739;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        @media (max-width: 768px) {
          font-size: 10px;
          font-weight: 500;
          line-height: 10px;
          padding: 8px 17px;
          border-radius: 10px;
        }

        .questionIcon {
        }
      }

      .unstakeBtnActive {
        transition: all 0.2s linear;
        background-color: white;
        color: black;

        .questionIcon {
          fill: black;
        }
      }
    }

    .stakeAmount {
      font-size: 32px;
      line-height: 45px;

      @media (max-width: 768px) {
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
      }

      img {
        width: 32px;
        height: 32px;

        @media (max-width: 768px) {
          width: 29px;
          height: 29px;
        }
      }
    }

    .stakePerDay {
      margin-bottom: 20px;
    }

    .disclaimer {
      padding: 11.5px 13px;
      border-radius: 20px;
      border: 1px solid #373739;
      display: flex;
      gap: 15px;
      margin-bottom: 12px;
      height: 74px;

      .disclaimerIcon {
        display: block;
        min-width: 25px;
        min-height: 25px;
      }

      p {
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        opacity: 0.5;
      }
    }
  }

  .historyCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;

    .currentRaffle {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;


    }

    .currentRaffleEnded {
      color: $color-purple;

      .eraNum {
        color: white;
        padding-left: 12px;
      }
    }

    .utcDate {
      font-size: 14px;
      font-weight: 400;
      line-height: 23.8px;
      opacity: 0.4;
    }
  }

  .waitingInfo {
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .waitingText {
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }

    .eraText,
    .eraNum {
      font-size: 32px;
      font-weight: 500;
      line-height: 32px;
      color: $color-purple;

      @media (max-width: 768px) {
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .eraNum {
      color: white;
      padding-left: 12px;
    }
  }

  .loserInfo,
  .winnerInfo {
    .infoText {
      margin-bottom: 24px;

      span {
        font-size: 32px;
        font-weight: 500;
        line-height: 32px;

        &:last-child {
          opacity: 0.6;
        }
      }
    }

    .infoSubText {
      max-width: 220px;
      width: 100%;
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      color: #A6A6A7;

      @media (max-width: 768px) {
        max-width: 180px;
      }

      b {
        color: white;
        opacity: 1;
      }
    }
  }

  .winnerInfo {
    .stakeBtnPurple {
      max-width: 200px;
      width: 100%;
      margin-top: 48px;
      cursor: pointer;
      position: relative;
      z-index: 1;
      text-transform: uppercase;
    }
  }
}

.stakeCardWrapperHistory {
  @media (max-width: 768px) {
    height: auto;
  }
}

.stakeBtnsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.stakeBtnPurple,
.stakeBtnGrey {
  width: calc(100%);
  padding: 21px;
  border-radius: 17px;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
}

.stakeBtnPurple {
  background: linear-gradient(90deg, #B364D8 68.02%, #9749BC 100%);
  color: white;
}

.stakeBtnGrey {
  border: 1px solid #373739;
  color: #FF3D3D;
}

.timerWrapper {
  background-color: #2C2C2E;
  border-radius: 17px;
  padding: 25px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    padding: 17px;
  }

  .timerGradient {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .timerText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
      text-align: center;

      span {
        opacity: 0.6;
      }
    }
  }

  .clockItems {
    display: flex;
    gap: 10px;

    .clockItem {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 17px;
      line-height: 17px;

      @media (max-width: 768px) {

        .clockValue,
        .clockDots {
          font-size: 28px;
          font-weight: 500;
          line-height: 28px;
        }
      }

      .clockValue {
        font-size: 24px;
        line-height: 24px;
      }

      .clockDots {
        opacity: 0.6;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        padding-left: 10px;
      }
    }
  }
}

.subModalWrapper {
  width: 100%;
  height: 610px;
  max-width: 444px;
  border: 1px solid #27272B;
  background-color: #151517;
  border-radius: 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 32px;

  .bubbleIcon,
  .contentIcon {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }

  .bubbleIcon {
  }

  .contentIcon {
    top: 98px;
  }

  .contentIconWithdrawn {
    top: 60px;
  }

  .contentIconWinner {
    width: 250px;
    height: 228px;
  }

  .stakingValue {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 49%;

    display: flex;
    align-items: center;
    gap: 8px;


    @media screen and (max-width: 445px) {
      top: 69vw;
    }

    img {
      min-width: 32px;
      width: 32px;
      height: 32px;
    }

    span {
      font-size: 32px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: #B364D8;
    }
  }

  .subModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .subModalTitle {
      font-size: 36px;
      font-weight: 700;
      line-height: 46.44px;
    }

    .subCloseIcon {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  .subModalFooter {
    .subtitle {
      font-size: 28px;
      font-weight: 700;
      line-height: 36.12px;
      margin-bottom: 23px;
    }

    .subtitleLight {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      opacity: 0.6;
      margin-bottom: 23px;
    }

    .stakeBtnsWrapper {
      * {
        text-transform: uppercase;
      }
    }

    .stakeBtnPurple {
      text-transform: uppercase;
    }
  }
}

.withdrawalWithoutPenalty {
  display: block;
  height: 74px;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  opacity: 0.6;
}
