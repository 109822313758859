.title {

}

.slider {
  margin: 36px auto 24px;
  position: relative;
  max-width: 616px;
  min-width: 230px;
  max-height: 308px;
  min-height: 156px;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 4;
    top: 0;
    height: 100%;
    width: 110px;
  }

  &:before {
    left: 0;
    background: linear-gradient(90deg, #151517 0%, rgba(21, 21, 23, 0.59) 41.98%, rgba(21, 21, 23, 0) 100%);
  }

  &:after {
    right: 0px;
    background: linear-gradient(90deg, rgba(21, 21, 23, 0) 0%, rgba(21, 21, 23, 0.59) 41.98%, #151517 100%);
  }
}

.swiper {
  >div {
    transition-timing-function: linear;
  }
}

.slide {
  border-radius: 30px;
  overflow: hidden;
}

.footer {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  max-width: 400px;
  margin: 0 auto;
}

.nft {}
