@use "../../../../../../styles/colors.module.scss" as colors;
@use "../../../../../../styles/mixins.scss" as mixins;
@use "../../../../../../styles/constants.module.scss" as consts;


.startStake {
  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
  }

  &__description {
    font-size: 14px;
    line-height: 19px;
    margin-top: 16px;
    opacity: 0.6;
  }

  &__button {
    background-color: #B364D8;
    margin-top: 24px;
    border-radius: 17px;
    padding: 20px 58px 20px 58px;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 44px;

    @include mixins.breakPoint(xl) {
      margin-top: 24px;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 16px;
    }
  }
}
