@use "../../../../styles/colors.module.scss" as colors;
@use "../../../../styles/fonts-mixins.scss" as fonts;


.wrapper {
  display: flex;
  flex-flow: column;
  gap: 8px;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0 14px;

  &_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: right;
  }
}

button.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  background: #212123;

  padding: 0 16px;

  color: white;
  height: 56px;
  padding: 8px 8px 8px 24px;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);


  &.not_connected {
    color: rgba(255, 255, 255, .6);
  }

  .text_name {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text_connect {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}