@use "../../../../../styles/fonts-mixins.scss" as fonts;
@use "../../../../../styles/constants.module" as consts;
@use "../../../../../styles/mixins" as mixins;

.customOtpCodeInput {
  margin-bottom: 24px;

  >div:first-child {
    gap: 4px;

    input {
      height: 63px;
    }

    @include mixins.breakPoint(2sm) {
      gap: 8px;

      input {
        height: 100px;
      }
    }
  }

  @include mixins.breakPoint(2sm) {
    margin-bottom: 32px;
  }
}

.modalBackdrop {
  background: rgba(0, 0, 0, 0.5) !important;

  &:focus {
    outline: none !important;
  }
}

.modalContent {
  position: fixed;
  top: 31px;
  bottom: 20px;
  margin: auto 0;
  max-height: max-content;

  &:focus {
    outline: none !important;
  }
}

.modalRoot {
  display: flex !important;
  height: 100% !important;
  width: 100% !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 40 !important;

  &:focus {
    outline: none !important;
  }
}

.modalBody {
  width: 100%;
  max-width: calc(100vw - 20px);
  min-height: 206px;
  padding: 24px 24px 32px;
  border-radius: 25px;
  background: #151517;
  border: 1px solid #1D1D20;

  @include mixins.breakPoint(2sm) {
    max-width: 582px;
  }

  &:focus {
    outline: none !important;
  }
}

.modalHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  @include mixins.breakPoint(2sm) {
    margin-bottom: 16px;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    text-align: left;

    @include mixins.breakPoint(2sm) {
      font-size: 34px;
      line-height: 34px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    text-align: left;

    span.white {
      color: #FFFFFF;
    }

    span {
      color: #FFFFFF99;

      span {
        color: #83D987;
      }
    }
  }
}

.modalText {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  margin-bottom: 24px;
  color: #FFFFFF99;

  span {
    color: white;
  }
}

@media (max-width: 480px) {
  .modalContentMobileFull {
    max-height: 100%;
  }

  .scrollableContainer {
    max-height: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .mobileFitScroll {
    height: fit-content !important;
    max-height: 90svh !important;
  }
}