@use "../../../../styles/constants.module" as consts;
@use "../../../../styles/colors.module" as colors;
@use "../../../../styles/mixins" as mixins;

.login-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__text {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    b,
    a {
      color: colors.$color-white !important;
    }

    a {
      @include mixins.breakPoint(xl) {
        &:hover {
          text-decoration: underline;
        }
      };
    }

    @include mixins.breakPoint(xl) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 14px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 9px 16px;
    @include mixins.buttonDark(10px);
    transition: all 0.2s ease;

    &:active {
      background-color: #232323;
      color: #D0D0D0;
      border-color: #2D2D2D;
    }

    @include mixins.breakPoint(xl) {
      &:hover {
        background-color: #272729;
        color: #D0D0D0;
        border-color: #303030;
      }
    }

    &:disabled {
      background-color: #1A1A1C;
      color: #444445;
      border-color: #1A1A1C;
    }

    span {
      display: none;
      font-weight: 500;

      @include mixins.breakPoint(xl) {
        display: block;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
