@keyframes scrollUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes scrollUpEven {
  0% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(-75%);
  }
}

.avatar-column {
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 176px;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.avatar-wrapper {
  display: flex;
  flex-direction: column;
  animation: scrollUp 60s linear infinite;
  height: 200%;
}

.avatar-column:nth-child(even) .avatar-wrapper {
  animation: scrollUpEven 60s linear infinite;
}

.avatar-card {
  width: 176px;
  height: 266px;
  margin: 6px;
}
