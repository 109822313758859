@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  width: 100%;
  --wcm-z-index: 1500 !important;

}

body {
  background-color: #090A17;
  color: white;
  height: 100%;
  width: 100%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border: none;
  outline: none;
  background-color: #090A17;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: 'unifontex';
  src: local('UnifontExMono'),
    url('./assets/fonts/UnifontExMono.woff2') format('woff2'),
    url('./assets/fonts/UnifontExMono.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  size-adjust: 115%;
}

.custom-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(179, 100, 215, .8);
  border-radius: 2px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scrollbar-x::-webkit-scrollbar {
  background-color: transparent;
  height: 4px;
}

.custom-scrollbar-x::-webkit-scrollbar-thumb {
  background-color: rgba(179, 100, 215, .8);
  border-radius: 2px;
}

.custom-scrollbar-x::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scrollbar-gray::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
}

.custom-scrollbar-gray::-webkit-scrollbar-thumb {
  background-color: #3E3E3E;
  border-radius: 3px;
  border: 1px solid #9999991f;
}

.custom-scrollbar-gray::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-dashed-border {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2) 50%, transparent 50%);
  background-size: 20px 2px;
  background-repeat: repeat-x;
  background-position: bottom;
  border-bottom: none;
}
