@use "../../styles/fonts-mixins.scss" as fonts;

.textarea {
  @include fonts.text-18;
  width: Fill (617px)px;
  padding: 8px 8px 8px 24px;
  border-radius: 20px;
  background: #212123;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &::placeholder {
    @include fonts.text-18;
    color: rgba(255, 255, 255, .6);
  }

}