@use "../../../../../../styles/mixins.scss";

.chooseAccountToLink {
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;

  @include mixins.breakPoint(2sm) {
    gap: 32px;
  }

  .avatars {
    display: flex;
    gap: 16px;

    @include mixins.breakPoint(2sm) {
      gap: 24px;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;

    button:first-child {
      flex-shrink: 2;
    }
  }
}