@use "../../../../styles/constants.module.scss" as consts;

.continer {
  height: 100%;
  display: grid;
  grid-template-rows: 50% 50%;

  .avatarWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;

    .levelDisplay {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 24px;
      right: 24px;

      span:first-child {
        font-size: 50px;
        font-weight: 500;
        line-height: 50px;
        text-align: center;
      }

      span:last-child {
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        opacity: 0.3;
      }
    }

    img {
      position: relative;
      z-index: 1;
      object-fit: contain;
    }

    .shine {
      position: absolute;
      left: 50%;
      bottom: -100px;
      transform: translateX(-50%);
      width: 100%;
      max-width: 100vw;
      height: 154px;
      border-radius: 50%;
      opacity: 0.7;
      background: radial-gradient(#B364D8 0%, transparent 70%, transparent 100%);
      z-index: 0;
    }
  }

  div.cardRoot {
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    .cardContent {
      overflow: auto;
    }

    .selectedPetName {
      font-size: 34px;
      font-weight: 500;
      line-height: 34px;
      text-align: left;
      margin-bottom: 9px;
    }

    .selectedPetInfoLabel {
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      margin-bottom: 19px;
      color: #FFFFFF66;
      display: block;
    }

    .selectedPetDesc {
      font-size: 14px;
      font-weight: 400;
      line-height: 23.8px;
      text-align: left;
      color: rgba(255, 255, 255, 0.6);
      height: 25%;
      min-height: 50px;
      overflow: auto;
    }

    .petMultiplierWrapper {}

    .petMultiplier {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      text-align: left;
      margin-bottom: 24px;

      span {
        color: #FFFFFF66;
      }
    }

  }
}

.btnRight {
  width: 100%;
}

.btnLeft {}

.petInfoBtns {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: consts.$mobile-breakpoint) {
    flex-direction: column;

    >button {
      width: 100%;
    }
  }

}