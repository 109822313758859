@use "../../../../styles/constants.module.scss" as consts;

.rewardsWrapper {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;

    .label {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }

    .value {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      text-align: left;
      color: rgba(255, 255, 255, 0.4);

      span {
        color: #FFFFFF;
      }

      @media (max-width: consts.$bp3xl) {
        font-size: 18px;
        line-height: 18px;
      }
    }

  }

  .streakWrapper {
    display: grid;
    grid-template-columns: 7fr 3fr;
    gap: 24px;
    width: 100%;
    height: 100%;
    align-items: flex-end;

    @media (max-width: consts.$mobile-breakpoint) {
      grid-template-columns: 1fr;
    }
  }

  .rewardsListWrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media (max-width: 768px) {}

    .rewardsList {
      padding-right: 0px;
      display: flex;
      flex-wrap: nowrap;
      gap: 8px;
      height: 100%;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: 0;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: 1200px) and (min-width: consts.$mobile-breakpoint) {}

      @media (max-width: consts.$mobile-breakpoint) {
        width: 100%;
        flex-wrap: wrap;
      }
    }

    li {
      @media (max-width: consts.$mobile-breakpoint) {
        width: calc(100% / 3 - 6px);
      }
    }
  }

  .firstRewardWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .rewardCardWrapper {
    display: grid;
    grid-template-rows: 1fr 17px;
    margin: 0;
    position: relative;
    height: 100%;
    max-width: 230px;
    border-radius: 16px;
    background: #151517;
    border: 1px solid #30303E;
    overflow: hidden;
    aspect-ratio: 94/111;

    @media (max-width: consts.$mobile-breakpoint) {
      width: 100%;
    }


    .cardTop {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      min-height: 100%;

      .imgWrapper {
        height: 100%;
        max-height: 28px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 9px;

        @media (min-width: consts.$bp3xl) {
          max-height: 56px;
        }

        img {
          max-height: 100%;
        }
      }
    }

    .rewardAmount {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      color: #FFD400;
    }

    .cardBottom {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 4px;
      width: 100%;
      top: 169px;
      font-size: 8px;
      font-weight: 500;
      line-height: 8px;
      text-align: center;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.4);
      background-color: #30303E;
    }

    .doneBg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 190px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  .rewardCardWrapperActive {
    border: 1px solid #83D987;
  }

  .rewardCardDisabled {
    background-color: rgb(48 48 62 / 40%);

    .cardBottom {
      color: #83D987;
    }

    .cardTop {
      * {
        opacity: 0.5;
      }
    }
  }
}