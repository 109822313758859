@import "../../../../styles/colors.module";
@import "../../../../styles/mixins";

$borderRadius: 25px;

.borderGradientContainer {
  width: 100%;
  height: unset;
  padding: 0px;
  border-radius: 0;
  background: none;

  @include breakPoint(2sm) {
    width: 100%;
    height: 100%;
    padding: 1px;
    border-radius: $borderRadius;
    background: linear-gradient(155.03deg, #1D1D20 23.72%, rgba(43, 44, 48, 0) 95.34%);
    overflow: hidden;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 0;
  background: none;
  @include breakPoint(2sm) {
    width: 100%;
    height: 100%;
    padding: 24px 12px 24px 24px;
    border-radius: $borderRadius;
    background: $new-color-dark;
  }
}

.scrollContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 0;
  @include breakPoint(2sm) {
    padding-right: 12px;
  }
}
