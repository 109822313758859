@use "../../../../../../styles/mixins.scss";

.container {
  width: 100%;

  .avatar {
    padding: 1px;
    background: linear-gradient(155.03deg, #1D1D20 23.72%, rgba(43, 44, 48, 0) 95.34%);
    border-radius: 25px;
    margin-bottom: 24px;
    overflow: hidden;

    >div {
      display: flex;
      gap: 16px;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #151517;
      padding: 15px 23px;
      border-radius: 25px;

      .avatarPlaceholder {
        position: relative;
        border: 1.33px solid #3A3042;
        border-radius: 50%;
        width: 60px;
        height: 60px;

        @include mixins.breakPoint(2sm) {
          width: 80px;
          height: 80px;
        }

        .shine {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 78px;
          height: 78px;
          border-radius: 50%;
          opacity: 0.5;
          background-color: #B76ADB;
          filter: blur(115px);
        }

        &:before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid #3A3042;
          border-radius: 50%;
          width: 100px;
          height: 100px;
          opacity: 0.4;

          @include mixins.breakPoint(2sm) {
            width: 120px;
            height: 120px;
          }
        }

        .img {
          width: 100%;
          height: 100%;
          min-width: 100%;
          min-height: 100%;
          border-radius: 50%;
          overflow: hidden;
          background-repeat: no-repeat;
          background-position: top center;
          background-size: cover;
        }
      }

      .title {
        position: relative;

        p:first-child {
          font-size: 18px;
          font-weight: 500;
          line-height: 25.2px;
          text-align: left;
          margin-bottom: 4px;

          @include mixins.breakPoint(2sm) {
            font-size: 20px;
            line-height: 28px;
          }
        }

        p:last-child {
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          text-align: left;
          opacity: 0.6;

          @include mixins.breakPoint(2sm) {
            font-size: 14px;
            line-height: 14px;
          }
        }
      }
    }
  }


  .notice {
    padding: 1px;
    margin-bottom: 16px;
    border-radius: 17px;
    background: linear-gradient(103deg, rgba(238, 161, 43, 0.35) 0.59%, rgba(136, 92, 25, 0.08) 97.91%);

    >div {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      gap: 16px;
      width: 100%;
      height: 100%;
      padding: 23px 15px;
      border-radius: 17px;
      background-color: #151517;

      @include mixins.breakPoint(2sm) {
        padding: 23px 27px 39px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        text-align: center;
        color: #FFFFFF99;

        @include mixins.breakPoint(2sm) {
          font-size: 16px;
          line-height: 22.4px;
        }

        span {
          color: #B364D8;
        }
      }
    }
  }

  .checkbox {
    margin-bottom: 24px;

    @include mixins.breakPoint(2sm) {
      margin-bottom: 32px;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;

    button:first-child {
      flex-shrink: 2;
    }
  }
}