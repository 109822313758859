@use "../../../styles/colors.module.scss" as colors;
@use "../../../styles/mixins.scss" as mixins;
@use "../../../styles/constants.module.scss" as consts;


.user-task {
  cursor: pointer;
  @include mixins.cardBase(17px);
  padding: 15px;
  transition: background-color consts.$transition-time;

  &:active:not(&--img) {
    background-color: transparentize(colors.$color-purple, 0.95);
  }

  @include mixins.breakPoint(xl) {
    padding: 24px;

    &:hover:not(&--img) {
      background-color: transparentize(colors.$color-purple, 0.95);
    }
  }

  &--img {
    padding: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    cursor: not-allowed;
    width: calc(100% + 20px);
    margin-left: -10px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &--done {
    border-color: colors.$color-green;
    opacity: 0.4;
  }

  &__header-new {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
  }

  &__reward-new {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: colors.$color-yellow;
    font-weight: 500;
    margin-top: 8px ;

    &::before {
      @include mixins.pseudo;
      position: relative;
      width: 18px;
      height: 18px;
      background: url("../../../assets/images/pix_icon_userinfo.svg") no-repeat center/cover;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: 500;
    color: colors.$color-green;
    margin-bottom: 6px;

    &--desktop {
      margin-bottom: 0;
      padding-top: 5px;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
    width: 100%;
  }

  &__goto-button {
    color: colors.$color-purple;
    padding-top: 6px;
    margin-left: auto;
    transition: opacity consts.$transition-time;

    &:active {
      opacity: 0.6;
    }

    @include mixins.breakPoint(xl) {
      padding-top: 8px;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__description {
    margin-top: 15px;
  }


  &__progress {
    position: relative;
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__progress-bar {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__progress-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 15px;

    span {
      display: block;
      font-size: 6px;
      opacity: .5;
    }
  }

  &__description {
    width: calc(100% - 60px);
    margin-top: 7px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: transparentize(colors.$color-white, 0.4);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 19px;
    margin-top: auto;
  }

  &__more-btn,
  &__goto-btn {
    display: flex;
    align-items: center;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 16px;
  }

  &__more-btn {
    column-gap: 18px;
    @include mixins.buttonDark(10px);
    transition: background-color consts.$transition-time;
    padding: 14px 16px;

    @include mixins.breakPoint(sb) {
      &:hover {
        background-color: transparentize(colors.$color-purple, .6);
      }
    }

    svg {
      transform: rotate(-90deg);
    }
  }

  &__goto-btn {
    column-gap: 15px;
    color: colors.$color-purple;
    transition: color consts.$transition-time;
    padding: 14px 0;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    @include mixins.breakPoint(sb) {
      &:hover {
        color: transparentize(colors.$color-purple, 0.2);
      }
    }

    ;
  }

  &__goto-btn-wrapper {
    display: flex;
    align-items: center;
    column-gap: 18px;
  }

  &__reward {
    font-weight: 500;
  }
}
