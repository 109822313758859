@use "../../styles/constants.module" as consts;
@use "../../styles/colors.module" as colors;
@use "../../styles/mixins" as mixins;

.not-auth-layout {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 7px 9px 19px;
  min-height: 100svh;

  @include mixins.breakPoint(xl) {
    padding: 22px 16px 23px;
    flex-direction: row;
    height: 100svh;
  }
}

.not-auth-layout-aside,
.not-auth-layout-content {
  display: flex;
  flex-direction: column;
}

.not-auth-layout-aside {
  position: relative;
  flex-grow: 1;
  padding: 20px 9px 0 13px;

  @include mixins.breakPoint(xl) {
    padding: 29px 23px 0 31px;
    background-image: url(../../assets/images/logBgDetails.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top 80px;
  }

  &::after,
  &::before {
    @include mixins.pseudo;
    display: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url(../../assets/images/logElement.png);

    @include mixins.breakPoint(xl) {
      display: block;
    }
  }

  &::after {
    left: -39px;
    top: 47%;
    width: 167px;
    height: 171px;
    transform: rotate(9deg);
  }

  &::before {
    right: -25px;
    top: 39%;
    width: 173px;
    height: 170px;
  }
}

.not-auth-layout-content {
  padding: 21px 18px 27px;
  z-index: 10;

  @include mixins.breakPoint(xl) {
    width: 551px;
    flex-shrink: 0;
    padding: 74px 25px 27px;
  }
}

.not-auth-layout-aside-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @include mixins.breakPoint(xl) {
    margin-bottom: 51px;
  }
}

.no-auth-layout-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.no-auth-layout-content-header {
  padding: 20px 0;
}
