.label {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

}

.checkboxIcon {
  margin-right: 3px;
  width: 20px;
  height: 20px;
  gap: 0px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 14px;
    height: 14px;
  }

  &.checked {
    background-color: #B364D8;
  }
}

.text {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 12px;
}

.relative {
  position: relative;
}

.hidden {
  display: none;
}