@use "../../styles/colors.module.scss" as colors;
@use "../../styles/mixins.scss" as mixins;
@use "../../styles/constants.module.scss" as consts;

.claim-page {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/claim/claim_bg_mobile.jpg");
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  @include mixins.cardBase(25px);
  //height: calc(100svh - 233px);

  @include mixins.breakPoint(lg) {
    padding: 24px;
    background-image: url("../../assets/images/claim/claim_bg_desktop.jpg");
  }
}
