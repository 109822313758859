@use "../../styles/mixins.scss" as mixins;

$overlayTransitionTime: .15s;

.base-layout {
  $this: &;

  display: grid;
  height: 100svh;

  @include mixins.breakPoint(sb) {
    grid-template-columns: 281px 1fr;
    column-gap: 32px;
  }

  &__sidebar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 285px;
    height: 100%;
    z-index: 30;
    transform: translateX(-100%);
    transition: transform $overlayTransitionTime;


    @include mixins.breakPoint(sb) {
      position: static;
      transform: translateX(0%);
      padding: 10px 0 12px 12px;
      grid-row: 1/-1;
      background-color: transparent;
      width: auto;
    }

    &--show {
      transform: translateX(0%);
    }
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 29;
    background: transparentize(#000, .3);
    opacity: 0;
    visibility: hidden;
    transition: visibility $overlayTransitionTime, opacity $overlayTransitionTime ease-in-out;

    @include mixins.breakPoint(sb) {
      display: none;
    }

    &--show {
      visibility: visible;
      opacity: 1;
      transition: visible $overlayTransitionTime, opacity $overlayTransitionTime ease-in;
    }
  }

  &__body {
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    &--hidden {
      overflow-y: hidden;
    }
  }

  &__header {
    padding: 8px 8px 0;

    @include mixins.breakPoint(sb) {
      padding: 10px 12px 0 0;
    }
  }

  &__content {
    padding: 24px 8px 48px;
    flex-grow: 1;

    @include mixins.breakPoint(sb) {
      padding: 24px 12px 12px 0;
    }
  }
}
