@use "../../../../styles/constants.module.scss" as consts;

.petsModalWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;

  .avatarActions {
    position: sticky;
    z-index: 99;
    width: 100%;
    left: 0px;
    bottom: 0px;

    @media (min-width: 1200px) {
      position: absolute;
    }

  }
}