@use "./constants.module.scss" as consts;
@use "./colors.module" as colors;

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

@mixin hideText {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

@mixin textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin placeholder {
  &::placeholder {
    @content;
  }
}

@mixin placeholderHidden {
  &::placeholder {
    color: transparent;
  }
}

@mixin pseudo($content: '', $position: absolute, $display: block) {
  content: $content;
  display: $display;
  position: $position;
}

@mixin breakPoint($size) {
  @if $size ==sb {
    @media (min-width: consts.$sidebar-breakpoint) {
      @content;
    }
  }

  @elseif $size ==3sm {
    @media (min-width: consts.$bp3sm) {
      @content;
    }
  }

  @elseif $size ==2sm {
    @media (min-width: consts.$bp2sm) {
      @content;
    }
  }

  @elseif $size ==sm {
    @media (min-width: consts.$bpsm) {
      @content;
    }
  }

  @elseif $size ==md {
    @media (min-width: consts.$bpmd) {
      @content;
    }
  }

  @elseif $size ==lg {
    @media (min-width: consts.$bplg) {
      @content;
    }
  }

  @elseif $size ==xl {
    @media (min-width: consts.$bpxl) {
      @content;
    }
  }

  @elseif $size ==2xl {
    @media (min-width: consts.$bp2xl) {
      @content;
    }
  }
}

@mixin cardBase($br) {
  background-color: colors.$new-color-dark;
  border: 1px solid colors.$new-color-gray;
  border-radius: $br;
}

@mixin resetCardBase() {
  background: transparent;
  border: none;
  border-radius: 0;
}

@mixin scrollbarHidden {
  -ms-overflow-style: none;
  /* ie 10+ */

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  /* хром, сафари */
}

@mixin buttonDark($br) {
  border-radius: $br;
  background: rgba(33, 33, 35, 1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

@mixin buttonAccent($br) {
  border-radius: $br;
  background-color: colors.$color-purple;
}

@mixin customScrollbarX {
  &::-webkit-scrollbar {
    background-color: transparent;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(179, 100, 215, .8);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

@mixin customScrollbar {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(179, 100, 215, .8);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

@mixin customScrollbarAddonGray {
  &::-webkit-scrollbar-thumb {
    background-color: #343436;
  }
}

@mixin huxley-decor {
  position: absolute;
  top: 0;
  width: 100%;
  height: 139px;
  border-radius: 32px;
  //box-shadow: inset 0 0 1px 0 transparentize(#D9D9D9, 0.8);
  border: 1px solid rgba(217, 217, 217, 0.2);
  border-bottom: 0;
  background: linear-gradient(180deg, #B92820 0%, rgba(185, 40, 32, 0) 69.82%);

  &::after {
    @include pseudo;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 114px;
    height: 16px;
    background-image: url(../assets/icons/huxley.svg);
  }

  &::before {
    @include pseudo;
    left: 50%;
    transform: translateX(-50%);
    bottom: 92%;
    width: 269px;
    height: 117px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-image: url(../assets/images/huxley-decor.png);

    @include breakPoint(md) {
      background-size: contain;
      width: 169px;
    }
  }
}

@mixin skeleton($h: 10px, $w: 100%, $br: 4px) {
  width: $w;
  height: $h;
  border-radius: $br;
  background-color: transparentize(colors.$color-purple, .5);
  background-image:
    linear-gradient(
        90deg,
        rgba(#454545, 0),
        rgba(#fff, 0.1),
        rgba(#272626, 0)
    );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}
