@use "../../../../styles/constants.module" as consts;
@use "../../../../styles/colors.module" as colors;
@use "../../../../styles/mixins" as mixins;

.login-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;

  &__body {
    padding: 40px 0 45px;

    .checkbox-field {
      margin-top: 22px;
      padding-left: 12px;

      @include mixins.breakPoint(xl) {
        padding-left: 20px;
      }
    }
  }

  &__code-label {
    padding-left: 12px;
    font-size: 14px;
    font-weight: 500;
  }

  &__back {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 16px;
    color: transparentize(colors.$color-white, 0.6);
    transition: color consts.$transition-time;
    margin-bottom: 15px;

    @include mixins.breakPoint(xl) {
      position: absolute;
      top: -43px;
      left: 0;

      &:hover {
        color: colors.$color-white;
      }
    }
  }

  &__or {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    span {
      z-index: 2;
      background-color: #151517;
      padding: 15px;
      color: transparentize(colors.$color-white, 0.6);
    }

    &::before {
      @include mixins.pseudo;
      width: 100%;
      height: 1px;
      top: calc(50% - 1px);
      left: 0;
      background-color: transparentize(colors.$color-white, 0.8);
      z-index: 1;
    }
  }
}
