@use "../../../../styles/mixins" as mixins;

.wrapper {
	width: 100%;
	height: 100%;
	max-height: 100vh;
	overflow: hidden;
	gap: 53px;
	padding: 1px;
	border-radius: 25px;
	background: linear-gradient(155.03deg, #1d1d20 23.72%, rgba(43, 44, 48, 0) 95.34%);

	&_inner {
		position: relative;
		display: flex;
		flex-flow: column;
		gap: 16px;
		width: 100%;
		height: 100%;
		max-height: 100%;
		overflow: hidden;
		padding: 24px 24px 8px;
		border-radius: 25px;
		background: #151517;

		@include mixins.breakPoint(sm) {
			gap: 30px;
		}

		&_header {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		&_title {
			font-size: 24px;
			font-weight: 500;
			line-height: 24px;
			text-align: left;
		}

		&_body {
			overflow: auto;
			position: relative;
		}
		&_list {
			display: flex;
			flex-flow: row wrap;
			gap: 6px;
			flex-grow: 1;
			max-height: 100%;
      padding-right: 4px;
			aspect-ratio: 3/4;
			overflow: auto;
			@include mixins.customScrollbar();
			@include mixins.customScrollbarAddonGray();

			@include mixins.breakPoint(sm) {
				@include mixins.customScrollbar();
				@include mixins.customScrollbarAddonGray();
				flex-flow: column nowrap;
				gap: 20px;
				aspect-ratio: unset;
			}
		}
	}

	&_bottom_fade {
		position: absolute;
		background: linear-gradient(180deg, rgba(21, 21, 23, 0) 0%, #151517 75.73%);
		right: unset;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 107px;
	}

  button.button {
    position: absolute;
    left: 0;
    bottom: 32px;
    &:disabled {
      background: linear-gradient(90deg, rgba(179, 100, 216, 0.6) 68.02%, rgba(151, 73, 188, 0.6) 100%);
    }
  }
}

