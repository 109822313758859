@use "../../../../styles/colors.module.scss" as colors;
@use "../../../../styles/mixins.scss" as mixins;
@use "../../../../styles/constants.module.scss" as consts;
@use "../../ClaimMixins.scss" as mixinsCl;

.claim-info {
  $this: &;
  width: 100%;
  max-width: 593px;

  &__content {
    @include mixinsCl.content;
  }

  &__button {
    @include mixinsCl.button;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    @include mixins.breakPoint(lg) {
      flex-direction: row;
    }
  }

  &__item {
    padding: 16px;
    width: 100%;
    @include mixins.cardBase(16px);

    &--colored {
      background-image: url("../../../../assets/images/claim/claim_bg_token.jpg");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: cover;

      #{$this}__count {
        color: colors.$color-purple;
      }
    }

    @include mixins.breakPoint(lg) {
      padding: 24px;
      border-radius: 25px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__count {
    margin-top: 27px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    font-size: 28px;
    color: colors.$color-green;

    @include mixins.breakPoint(lg) {
      margin-top: 14px;
    }
  }

  &__img {
    width: 32px;
    height: 32px;
  }

  &__info-button {
    margin-top: 15px;
    @include mixinsCl.button;

    @include mixins.breakPoint(lg) {
      margin-top: 26px;
    }

    &:disabled {
      background-color: #35253E;
      pointer-events: none;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__details-list {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    @include mixins.breakPoint(lg) {
      row-gap: 24px;
    }
  }

  &__details-item {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    column-gap: 4px;

    @include mixins.breakPoint(lg) {
      font-size: 18px;
    }
  }

  &__details-item-label {
    font-weight: 400;
    opacity: 0.5;
  }

  &__info-button-pre-word {
    margin-right: 4px;
    opacity: 0.4;
  }
}
