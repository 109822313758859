@use "../../../../styles/mixins.scss" as mixins;

.navigation {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  &__item {
    display: flex;
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__info {
    margin-top: 42px;
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    display: none;
  }
}
