@use "../../styles/mixins.scss" as mixins;

.container {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  width: 100%;
  outline: none;
  cursor: pointer;

  @include mixins.breakPoint(2sm) {
    align-items: center;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    outline: none;

    &:checked+.knob {
      background-color: #B364D8;
    }

    &:focus+.knob {
      box-shadow: 0 0 1px #B364D8;
    }

    &:checked+.knob:before {
      color: #000000;
      content: '';
      background-color: #ffffff;
      transform: translateX(20px);
    }
  }

  .knob {
    position: relative;
    cursor: pointer;
    min-width: 44px;
    max-width: 44px;
    height: 24px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF26;
    transition: .2s;
    border-radius: 24px;
    outline: none;

    &:before {
      background-color: #ffffff;
      color: #000000;
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      transition: .2s;
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .label {
    user-select: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    text-align: left;
  }
}