@use "../../styles/colors.module" as colors;
@use "../../styles/mixins" as mixins;
@use "../../styles/constants.module" as consts;

@mixin content {
  margin-top: 10px;
  padding: 15px;
  overflow: auto;
  //height: calc(100svh - 440px);
  @include mixins.cardBase(20px);

  @include mixins.breakPoint(lg) {
    padding: 40px 32px;
    border-radius: 25px;
  }
}

@mixin button {
  padding: 17px;
  width: 100%;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  border-radius: 17px;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  background-color: colors.$color-purple;
  transition: all consts.$transition-time;
  font-family: "Rubik", sans-serif;

  &:hover {
    background-color: transparentize(colors.$color-purple, 0.6);
  }

  @include mixins.breakPoint(lg) {
    padding: 20px;
    min-height: 64px;
  }
}
