@use "../../../../../styles/colors.module" as colors;
@use "../../../../../styles/mixins" as mixins;

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;

  @include mixins.breakPoint(sb) {
    &--staking {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  &__avatar {
    width: 44px;
    height: 44px;
    border: 1px solid colors.$new_border_color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;

    @include mixins.breakPoint(sb) {
      width: 48px;
      height: 48px;
    }
  }

  &__content {
    font-weight: 500;
    font-size: 14px;
    margin-left: 12px;

    @include mixins.breakPoint(sb) {
      font-size: 16px;
    }
  }

  &__username {
    display: block;
    max-width: 300px;
    @include mixins.textEllipsis;
  }

  &__points {
    position: relative;
    display: flex;
    color: colors.$color-yellow;
    padding-left: 23px;
    margin-top: 4px;

    &::before {
      @include mixins.pseudo;
      top: calc(50% - 9px);
      left: 0;
      width: 18px;
      height: 18px;
      background: url("../../../../../assets/images/pix_icon_userinfo.png") no-repeat;
    }
  }

  &__dropdown-button {
    margin-left: auto;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}