@use "../../../styles/colors.module.scss" as colors;

.element-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 1;

  svg {
    width: 20px;
    height: 20px;
  }
}
