@use "../../../styles/colors.module" as colors;
@use "../../../styles/mixins" as mixins;
@use "../../../styles/constants.module" as consts;

.referrals {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include mixins.breakPoint(xl) {
    flex-direction: row;
    height: 100%;
  }

  &__left-col {
    padding: 16px;
    display: flex;
    flex-direction: column;

    @include mixins.breakPoint(xl) {
      width: 53.5%;
      padding: 24px 24px 32px;
    }
  }

  &__right-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto 1fr;
    gap: 8px;

    @include mixins.breakPoint(xl) {
      width: 46.5%;
    }
  }

  &__copy {
    margin-top: 24px;
    flex-grow: 1;
  }

  &__desc {
    margin-top: 16px;
  }

  &__table-wrapper {
    grid-column: 1/-1;
    padding: 24px;
    display: flex;
    flex-direction: column;
  }

  &__table {
    margin-top: 16px;
    flex-grow: 1;
  }
}
