@use "../../../../../styles/mixins" as mixins;
@use "../../../../../styles/colors.module" as colors;
@use "../../../../../styles/constants.module" as consts;

.referral-table {
  //height: calc(100svh - 350px);
  overflow: auto;
  @include mixins.customScrollbar();

  &--empty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    max-height: 296px;
    height: 100%;
    @include mixins.customScrollbar();

    @include mixins.breakPoint(md) {
      max-height: none;
      margin-top: 10px;
      height: calc(100svh - 575px);
    }

    &--empty {
      max-height: 296px;
      overflow: hidden;
      flex-grow: 1;

      @include mixins.breakPoint(md) {
        overflow: auto;
        max-height: none;
      }
    }
  }

  &__loader {
    align-self: center;
    flex-grow: 1;
  }

  &__empty {
    position: relative;
    overflow: hidden;

    &-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mixins.breakPoint(xl) {
        font-size: 24px;
      }
    }
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //padding: 10px;
    //background: url("../../../../../assets/images/referrals/rt-empty.png") top / cover no-repeat;
    //min-height: 275px;
    //
    //@include mixins.breakPoint(md) {
    //  min-height: 220px;
    //}
  }
}
