@use "../../../../styles/constants.module" as consts;
@use "../../../../styles/colors.module" as colors;
@use "../../../../styles/mixins" as mixins;


.login-social {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    background-color: #2C2C2E;
    border: 1px solid transparentize(colors.$color-white, 0.9);
    border-radius: 17px;
    transition: all consts.$transition-time;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include mixins.breakPoint(xl) {
      &:hover:not(&[disabled]) {
        background-color: #65656C;
      }
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
}
