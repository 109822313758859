@use "../../../../../styles/mixins" as mixins;

.account-email-linking-modal {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  @include mixins.breakPoint(2sm) {
    gap: 24px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 8px;

    button:first-child {
      flex-shrink: 2;
    }
  }

}