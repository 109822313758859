@use "../../../styles/mixins" as mixins;
@use "../../../styles/colors.module" as colors;

.not-auth-aside-content {
  z-index: 10;
  //max-width: 245px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  //@include mixins.breakPoint(xl) {
  //  max-width: 445px;
  //}

  &__badge {
    height: 28px;
    font-size: 12px;
    border-radius: 17px;
    border: 1px solid colors.$color-purple;
    background-color: colors.$color-purple;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    text-transform: uppercase;
    color: transparentize(colors.$color-white, 0.6);
    transform: rotate(-3deg);
    min-width: 142px;
    margin-bottom: 5px;

    @include mixins.breakPoint(xl) {
      border-radius: 25px;
      height: 43px;
      min-width: 198px;
      font-size: 15px;
    }

    b {
      font-weight: 500;
      color: colors.$color-white;
      display: block;
      margin-left: 3px;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 500;

    br {
      display: none;

      @include mixins.breakPoint(xl) {
        display: block;
      }
    }

    b {
      font-weight: 500;

      @include mixins.breakPoint(xl) {
        color: colors.$color-purple;
      }
    }

    @include mixins.breakPoint(xl) {
      font-size: 78px;
      line-height: 78px;
    }

    &--secondary {
      @include mixins.breakPoint(xl) {
        font-size: 58px;
        line-height: 58px;
      }
    }
  }

  &__text {
    max-width: 445px;
    margin-top: 14px;

    @include mixins.breakPoint(xl) {
      margin-top: 23px;
    }
  }
}
