@use "../../styles/constants.module.scss" as consts;


.wrapper_horizontal {
  display: grid;
  grid-template-rows: 70% 30%;
  gap: 8px;
  height: 100%;

  @media (max-width: consts.$mobile-breakpoint) {
    grid-template-rows: auto auto;
  }
}

.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 9fr 8fr;
  gap: 12px;

  @media screen and (max-width: consts.$sidebar-breakpoint) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: consts.$mobile-breakpoint) {
    grid-template-columns: 1fr;
  }

  .leftContainer {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 48px;
    max-height: 100%;
    width: 100%;
    padding: 24px;
    border-radius: 25px;
    overflow: hidden;

    background-image: url("../../assets/images/profile_avatar_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: consts.$mobile-breakpoint) {
      max-width: 100%;
      margin: 0 auto;
      padding-bottom: 28px;
    }
  }

  .rightContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;

    @media screen and (max-width: consts.$mobile-breakpoint) {
      overflow: visible;
    }
  }
}

.modal_header_text {
  display: none;

  @media screen and (min-width: consts.$mobile-breakpoint) {
    display: block;
  }
}



.dashboardWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  gap: 64px;

  @media (max-width: 1200px) {
    flex-direction: column;
    width: 100%;

    // .selectedPet,
    // .petInfo {
    //   width: 100% !important;
    // }
  }

  .petContainer {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap: 32px;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
      flex-flow: column;
    }
  }

  .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }


  .selectedPet {
    position: relative;
    width: 45%;
    max-height: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    height: calc(100vh - 160px);

    @media (max-width: 980px) {
      height: calc(100vh - 230px);
    }

    @media (max-width: 768px) {
      max-height: 464px;
      max-width: 100%;
      align-self: center;
      // height: 90vw;
      height: 60vw;
    }

    .mainPetImg {
      position: absolute;
      width: 100%;
      max-width: 400px;
      bottom: 15vh;

      @media (max-width: 980px) {
        bottom: 10vh;
      }

      @media (max-width: 768px) {
        // margin-bottom: 74px;
        bottom: 60px;
      }
    }

    .petFloor {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        padding-bottom: 0px;
        padding-top: 245px;
        height: 296px;
      }
    }
  }

  .petInfo {
    width: 55%;
    max-width: 598px;
    min-height: 670px;
    align-self: center;

    @media screen and (max-width: consts.$mobile-breakpoint) {
      width: 100%;
      align-self: center;
    }

    .petsBody {
      background-color: #0e0f1c;
      width: 100%;
      min-height: 670px;
      height: 100%;
      padding: 20px 28px;

      .petsHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #232330;

        .petTitle {
          font-size: 28px;
          font-weight: 400;
          line-height: 36.12px;
          flex: 1;
          width: 100%;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @media screen and (max-width: 768px) {
            width: 200px;
          }

        }

        .editButtonContainer {
          width: 40;
          cursor: "pointer";

          .editIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            transition: opacity 250ms ease;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .petsInfoTitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 18.06px;
        margin-bottom: 12px;
      }

      .petsDescription {
        display: flex;
        flex-direction: column;
      }

      .petsInfoSubtitle {
        line-height: 23.8px;
        opacity: 0.6;
        padding-bottom: 24px;
      }

      .petsCurrentLevel {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: rgba(255, 255, 255, .6);
        margin-bottom: 20px;
        border-bottom-width: 1px;
        border-color: rgb(35 35 48 / 1);
        padding-bottom: 20px;

        .levelBlock {
          display: flex;
          gap: 8px;
          align-items: center;
        }

        .levelValue {
          font-size: 0.75rem;
          line-height: 1rem;
        }
      }
    }
  }
}

.updatePetButtonWrapper {
  position: relative !important;
  z-index: 1;
  width: 226px !important;
}

.updatePetButton {
  width: 214px;
}

.petsModalWrapper {
  width: 1360px;
  padding: 16px 28px 36px;
  display: flex;
  gap: 40px;
  align-items: flex-start;

  @media (max-width: 1390px) {
    width: 95vw;
  }

  @media (max-width: 1230px) {
    gap: 20px;
  }

  @media (max-width: 768px) {
    padding: 16px 16px 36px;
    height: 56%;
  }

  .petsCount,
  .perInfoTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 23.22px;
    margin-bottom: 16px;
  }

  .petsListWrapper {
    width: 60.7%;

    @media (max-width: 768px) {
      width: 100%;
    }

    .petsList {
      display: flex;
      flex-wrap: wrap;
      height: 485px;
      gap: 16px;
      overflow: auto;

      @media (max-width: 768px) {
        height: calc(90svh - 244px);
      }

      .loaderWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .petItem {
        cursor: pointer;
        width: calc(25% - 12px);
        margin-bottom: 16px;
        height: 230px;

        @media (max-width: 1230px) {
          width: calc(33% - 6px);
        }

        @media (max-width: 960px) {
          width: calc(50% - 6px);
        }

        @media (max-width: 649px) {
          width: 100%;
        }

        .petItemBody {
          .levelWarning {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: absolute;
            padding: 16px;

            span span {
              font-size: 0.75rem;
              line-height: 1rem;
              margin-left: 0.5rem;
            }
          }

          .labelCurrent {
            position: absolute;
            top: 9px;
            right: 0.5rem;
          }

          .labelPrice {
            position: absolute;
            bottom: 50px;
            right: 50%;
            transform: translateX(50%);
          }

          .petItemImgWrapper {
            width: 100%;
            background-color: #151623;
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;

            .petItemImg {
              height: 158px;
              max-width: initial;
            }
          }

          .petItemName {
            min-height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;

            font-size: 15px;
            font-weight: 400;
            line-height: 19.35px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .petItemLowLevel {
        .petItemName {
          background-color: #1d1e2b !important;
        }

        .cpixelatedBorderWrapper {
          height: fit-content !important;
        }
      }
    }
  }

  .petsListWrapperWide {
    width: 100% !important;
  }
}

.btnsBlock {
  padding-top: 32px;
  display: flex;
  gap: 16px;
}

.perInfoTitle {
  @media (max-width: 768px) {
    display: none;
  }
}

.petsInfo {
  width: 39.3%;

  @media (max-width: 768px) {
    width: calc(100% - 24px);
    margin: 25px auto 52px;
  }

  .petsInfoBody {
    background-color: #232330;

    .petsInfoImgWrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #151623;
      height: 235px;
      margin-bottom: 16px;

      img {
        height: 230px;
      }
    }
  }

  .selectedPetInfoDescWrapper {
    background-color: #232330;
    padding: 16px 28px;

    .selectedPetInfoName {
      font-size: 20px;
      font-weight: 400;
      line-height: 25.8px;
      margin-bottom: 12px;
    }

    .selectedPetInfoDesc {
      line-height: 26.6px;
      opacity: 0.6;
      height: 108px;
      overflow: auto;
    }

    .petMultiplierWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 32px;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
      }

      .petMultiplier {

        &:last-child {
          color: #b364d8;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          text-align: left;
          opacity: 0.6;
        }

        b {
          font-size: 18px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }

    .petInfoBtns {
      display: flex;
      gap: 16px;

      .petInfoBtn {
        flex: 1;
        height: 54px;

        font-size: 17px;
        font-weight: 400;
        line-height: 27px;
        transition: all 0.2s linear;

        &:hover {
          backdrop-filter: brightness(1.1);
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .btnBody {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.checked {
  position: absolute;
  right: 16px;
  top: 16px;
}

.petsInfoModalWrapper {
  width: 95vw;
}

.editPetNameModalWrapper {
  width: 100%;

  @media (max-width: 768px) {
    width: 95vw;
  }

  .inputLabel {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.06px;
    padding-bottom: 16px !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .inputConponent {
    font-size: 1rem;
  }
}

.proofOfActionModalWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 29px;
  width: 100%;
  height: 100%;

  @media (max-width: 520px) {
    width: 95vw;
  }

  .proofOfActionSubtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 28.8px;

    opacity: 0.6;
  }
}

.btnsWrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 16px;

  button:first-child {
    width: initial;
    min-width: 170px;
  }
}

.confirmSellPetModalWrapper {
  width: 100%;

  @media (max-width: 768px) {}

  @media (max-width: 480px) {
    width: 95vw;
  }

  .sellAvatarCard {
    min-width: 165px;
  }

  .confirmSellPetInfo {
    display: flex;
    flex-direction: row;
    gap: 24px;

    .left {
      width: 30%;
      padding: 0 0 20px 0;
      display: flex;
      align-items: center;

      .confirmSellImgWrapper {
        height: 180px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        .confirmSellImg {
          height: 180px;
        }
      }

      .confirmSellPetName {
        width: 100%;
        min-height: 40px;
        background-color: #232330;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #b364d8;
      }
    }

    .right {
      width: 70%;

      .confirmSellPetTitle {
        font-size: 20px;
        font-weight: 400;
        line-height: 25.8px;
        margin-bottom: 12px;
      }

      .confirmSellPetSubtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 23.8px;
        text-align: left;
        opacity: 0.6;
        margin-bottom: 24px;
      }

      .incomeWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;

        .label {
          opacity: 0.6;
        }
      }
    }
  }
}

.aboutPetContainer {
  max-width: 1064px;
  width: 100%;
  margin: 0 auto 60px;

  .aboutPetWrapper {
    width: 100%;
    background-color: #151623;
    padding: 32px 26px;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      padding: 14px 12px;
    }

    .aboutPetTitle {
      font-size: 24px;
      font-weight: 400;
      line-height: 30.96px;
      text-align: left;
      padding-bottom: 20px;
      border-bottom: 1px solid #232330;
      margin-bottom: 30px;

      @media (max-width: 1536px) {
        font-size: 20px;
        font-weight: 400;
        line-height: 25.8px;
        margin: 12px 12px 0px 0px;
      }
    }

    .aboutPetContent {
      display: flex;
      flex-direction: row;
      gap: 24px;
      flex: 1;

      @media (max-width: 1200px) {
        flex-direction: column;
      }

      .aboutPetText {
        flex: 1;

        h4 {
          font-size: 13px;
          font-weight: 400;
          line-height: 20.64px;
          margin-bottom: 12px;
          padding: 20px 0 6px 0;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 23.8px;
          opacity: 0.6;
        }
      }

      .aboutPetPetsList {
        border-top: 1px solid #232330;
        padding-top: 24px;
        width: 100%;
        flex: 1;
        max-height: 52vh;
        overflow: auto;

        @media (max-width: 1200px) {
          margin: 0 auto;
        }

        .aboutPetsList {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          width: 100%;

          .aboutPetItem {
            cursor: pointer;
            width: calc(50% - 8px);
            margin-bottom: 16px;
            height: 300px;

            @media (max-width: 1200px) {
              height: 192px;

              .petItemImgWrapper {
                height: 142px !important;

                .petItemImg {
                  height: 132px !important;
                }
              }
            }

            .petItemBody {
              .levelWarning {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                position: absolute;
                padding: 16px;
              }

              .petItemImgWrapper {
                width: 100%;
                background-color: #151623;
                height: 230px;
                display: flex;
                align-items: center;
                justify-content: center;

                .petItemImg {
                  height: 208px;
                }
              }

              .petItemName {
                min-height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;

                font-size: 15px;
                font-weight: 400;
                line-height: 19.35px;
                text-align: center;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }
    }

    .chooseBtnWrapper {
      border-top: 1px solid #232330;
      padding-top: 32px;
      margin-top: 24px;
    }
  }
}

@media (max-width: 480px) {
  .confirmSellPetModalWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .confirmSellPetInfo {
    flex-direction: column !important;
    flex: 1;

    .left,
    .right {
      width: 100% !important;
    }
  }
}