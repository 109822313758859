@use "../../../../styles/colors.module.scss" as colors;
@use "../../../../styles/mixins.scss" as mixins;
@use "../../../../styles/constants.module.scss" as consts;

@mixin tableText() {
  &::before {
    @include mixins.pseudo;
    position: relative;
    width: 32px;
    height: 32px;
    background: url("../../../../assets/images/pix_icon_userinfo.svg") no-repeat center/cover;
  }
}


.referrals-info {
  @include mixins.cardBase(25px);
  padding: 16px;
  display: flex;
  flex-direction: column;

  &__count {
    margin-top: 40px;
    font-size: 32px;
    font-weight: 500;

    @include mixins.breakPoint(sb) {
      margin-top: 64px;
      font-size: 40px;
    }

    &--points {
      display: flex;
      align-items: center;
      column-gap: 8px;
      @include tableText();

      @include mixins.breakPoint(xl) {
        column-gap: 16px;
      }
    }
  }

  &__subtext {
    display: none;
    align-self: end;
    font-size: 20px;
    opacity: 0.6;

    @include mixins.breakPoint(sb) {
      display: block;
    }
  }
}
