@import "../../../../styles/mixins";

.wrapper {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
  aspect-ratio: 343/156;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #171719 0%, transparent 50%, #1C1C1E 100%);
  }

	>div {
		position: relative;
		overflow: visible;
		.img {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			aspect-ratio: 1/1;
			border-radius: 16px;
			overflow: hidden;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
		}
	}
	>div:nth-child(1),
	>div:nth-child(7) {
		width: 9.5%;
		height: 56%;
		z-index: 1;
    display: none;
	}
	>div:nth-child(2),
	>div:nth-child(6) {
		width: 11.5%;
		height: 56%;
		z-index: 2;
	}
	>div:nth-child(3),
	>div:nth-child(5) {
		width: 16%;
		height: 72%;
		z-index: 3;
	}
	>div:nth-child(4) {
		width: 45%;
		aspect-ratio: 1/1;
		z-index: 4;
	}

	>div:nth-child(7) .img {
		left: unset;
		right: 0;
	}
	>div:nth-child(6) .img {
		left: unset;
		right: 0;
	}
	>div:nth-child(5) .img {
		left: unset;
		right: 0;
	}
}

@include breakPoint(2sm) {
  .wrapper {
    aspect-ratio: 178/49;

    > div {
      .img {
        border-radius: 20px;
      }
    }

    > div:nth-child(1),
    > div:nth-child(7) {
      width: 9.5%;
      height: 56%;
      display: block;
    }

    > div:nth-child(2),
    > div:nth-child(6) {
      width: 11.5%;
      height: 72%;
    }

    > div:nth-child(3),
    > div:nth-child(5) {
      width: 15%;
      height: 84%;
    }

    > div:nth-child(4) {
      width: 28%;
    }
  }
}
